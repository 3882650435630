import axios from 'axios';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import iconCheck from '../../assets/icons/check.svg';
import iconEllipsis from '../../assets/icons/ellipsis.svg';
import iconMinus from '../../assets/icons/minus.svg';
import iconPlus from '../../assets/icons/plus.svg';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ModalAddTask } from '../../components/modals/add-task';
import { ModalEditTask } from '../../components/modals/edit-task';
import { Popup } from '../../components/popup/popup';
import BASE_URL from '../../services/apiConfig';
import { authHeader } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from './tasks.module.scss';

export const Tasks = ({}) => {
    const [showModalEditTask, setShowModalEditTask] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [modalEditTaskId, setModalEditTaskId] = useState(0);
    const [showModalAddTask, setShowModalAddTask] = useState(false);

    const [tasksListExpiredCompletedLength, setTasksListExpiredCompletedLength] = useState(0);
    const [tasksListExpiredOpen, setTasksListExpiredOpen] = useState(false);
    const [tasksListExpired, setTasksListExpired] = useState<any>([]);

    const [tasksListTodayCompletedLength, setTasksListTodayCompletedLength] = useState(0);
    const [tasksListTodayOpen, setTasksListTodayOpen] = useState(true);
    const [tasksListToday, setTasksListToday] = useState<any>([]);

    const [tasksListWeekCompletedLength, setTasksListWeekCompletedLength] = useState(0);
    const [tasksListWeekOpen, setTasksListWeekOpen] = useState(false);
    const [tasksListWeek, setTasksListWeek] = useState<any>([]);

    const [tasksListMonthCompletedLength, setTasksListMonthCompletedLength] = useState(0);
    const [tasksListMonthOpen, setTasksListMonthOpen] = useState(false);
    const [tasksListMonth, setTasksListMonth] = useState<any>([]);

    const openModalTaskEdit = (id: any) => {
        setModalEditTaskId(id);
        setShowModalEditTask(true);
    };
    const fetchData = () => {
        axios
            .get(`${BASE_URL}/tasks/expired`, { headers: authHeader() })
            .then((response) => {
                const tasks: any = response.data.results;
                setTasksListExpired(tasks);
            })
            .catch((error) => {
                console.error('Error fetching task data:', error);
            });
        axios
            .get(`${BASE_URL}/tasks/today`, { headers: authHeader() })
            .then((response) => {
                const tasks: any = response.data.results;
                setTasksListToday(tasks);
            })
            .catch((error) => {
                console.error('Error fetching task data:', error);
            });

        axios
            .get(`${BASE_URL}/tasks/week`, { headers: authHeader() })
            .then((response) => {
                const tasks: any = response.data.results;
                setTasksListWeek(tasks);
            })
            .catch((error) => {
                console.error('Error fetching task data:', error);
            });

        axios
            .get(`${BASE_URL}/tasks/month`, { headers: authHeader() })
            .then((response) => {
                const tasks: any = response.data.results;
                setTasksListMonth(tasks);
            })
            .catch((error) => {
                console.error('Error fetching task data:', error);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        var i = 0;
        if (tasksListToday.length > 0) {
            tasksListToday.forEach((task: any, index: number) => {
                if (task.completed == 1) {
                    i++;
                }
                if (index + 1 == tasksListToday.length) {
                    setTasksListTodayCompletedLength(i);
                }
            });
        }
    }, [tasksListToday]);

    const toggleTaskGroupExpired = () => {
        var tempTasksListExpiredOpen = !tasksListExpiredOpen;
        setTasksListExpiredOpen(tempTasksListExpiredOpen);
    };

    const toggleTaskGroupToday = () => {
        var tempTasksListTodayOpen = !tasksListTodayOpen;
        setTasksListTodayOpen(tempTasksListTodayOpen);
    };

    const toggleTaskCompletedToday = (e: any, index: number, id: number) => {
        var tempTasksListToday = tasksListToday.slice();
        var completed = tempTasksListToday[index].completed;
        if (completed == 1) {
            tempTasksListToday[index].completed = 0;
        } else {
            tempTasksListToday[index].completed = 1;
        }
        updateTaskComplete(id, tempTasksListToday[index].completed);
        setTasksListToday(tempTasksListToday);
    };

    useEffect(() => {
        var i = 0;
        if (tasksListWeek.length > 0) {
            tasksListWeek.forEach((task: any, index: number) => {
                if (task.completed == 1) {
                    i++;
                }
                if (index + 1 == tasksListWeek.length) {
                    setTasksListWeekCompletedLength(i);
                }
            });
        }
    }, [tasksListWeek]);

    const toggleTaskGroupWeek = () => {
        var tempTasksListWeekOpen = !tasksListWeekOpen;
        setTasksListWeekOpen(tempTasksListWeekOpen);
    };

    const toggleTaskCompletedWeek = (e: any, index: number, id: number) => {
        var tempTasksListWeek = tasksListWeek.slice();
        var completed = tempTasksListWeek[index].completed;
        if (completed == 1) {
            tempTasksListWeek[index].completed = 0;
        } else {
            tempTasksListWeek[index].completed = 1;
        }
        updateTaskComplete(id, tempTasksListWeek[index].completed);
        setTasksListWeek(tempTasksListWeek);
    };

    useEffect(() => {
        var i = 0;
        if (tasksListMonth.length > 0) {
            tasksListMonth.forEach((task: any, index: number) => {
                if (task.completed == 1) {
                    i++;
                }
                if (index + 1 == tasksListMonth.length) {
                    setTasksListMonthCompletedLength(i);
                }
            });
        }
    }, [tasksListMonth]);

    const toggleTaskGroupMonth = () => {
        var tempTasksListMonthOpen = !tasksListMonthOpen;
        setTasksListMonthOpen(tempTasksListMonthOpen);
    };

    const toggleTaskCompletedMonth = (e: any, index: number, id: number) => {
        var tempTasksListMonth = tasksListMonth.slice();
        var completed = tempTasksListMonth[index].completed;
        if (completed == 1) {
            tempTasksListMonth[index].completed = 0;
        } else {
            tempTasksListMonth[index].completed = 1;
        }
        updateTaskComplete(id, tempTasksListMonth[index].completed);
        setTasksListMonth(tempTasksListMonth);
    };

    const updateTaskComplete = async (taskId: number, completed: number) => {
        try {
            const updatedData = {
                taskId,
                completed,
            };

            await axios.put(`${BASE_URL}/task/${taskId}/complete`, updatedData, {
                headers: authHeader(),
            });
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };

    const callBackTaskEdit = async () => {
        fetchData();
        setShowModalEditTask(false);
    };
    const callBackTaskAdd = async () => {
        fetchData();
        setShowModalAddTask(false);
    };
    const callBackClosePopup = async () => {
        setShowPopup(false);
    };

    const formattedTime = (time: any, format: any) => {
        const hours = DateTime.fromSeconds(time).toFormat('H', { locale: 'nl' });
        const minutes = DateTime.fromSeconds(time).toFormat('mm', { locale: 'nl' });

        var time2 = '';
        if (format == 'expired') {
            const day = DateTime.fromSeconds(time).toFormat('DD', { locale: 'nl' });
            time2 = day + ' ' + tl('before') + ' ' + hours + ':' + minutes;
        }
        if (format == 'hours') {
            time2 = tl('Today before') + ' ' + hours + ':' + minutes;
        }
        if (format == 'days') {
            const day = DateTime.fromSeconds(time).toFormat('DD', { locale: 'nl' });
            time2 = day + ' ' + tl('before') + ' ' + hours + ':' + minutes;
        }
        if (format == 'weeks') {
            const day = DateTime.fromSeconds(time).toFormat('DD', { locale: 'nl' });
            time2 = day + ' ' + tl('before') + ' ' + hours + ':' + minutes;
        }
        return time2;
    };

    console.log();

    return (
        <>
            <HeaderTitle title={tl('Tasks')} description={``} />

            <section className={styles['task-list-container']}>
                <div onClick={() => setShowPopup(true)}></div>
                <div className="grid-container">
                    <div className={styles['task-group']}>
                        <div
                            className={styles['task-group-header']}
                            onClick={(e) => toggleTaskGroupExpired()}
                        >
                            <div className={styles['task-group-toggle']}>
                                {tasksListExpiredOpen ? (
                                    <ReactSVG src={iconMinus} />
                                ) : (
                                    <ReactSVG src={iconPlus} />
                                )}
                            </div>
                            <div className={styles['task-group-title']}>{tl('Backlog Tasks')}</div>
                            <div
                                className={`${styles['task-group-count']} ${
                                    tasksListExpiredCompletedLength == tasksListExpired.length &&
                                    styles['-completed']
                                }`}
                            >
                                {tasksListExpiredCompletedLength}/{tasksListExpired.length}
                            </div>
                        </div>
                        <div className={styles['task-group-content']}>
                            <div className={styles['task-group-inner']}>
                                {tasksListExpired.length > 0 && tasksListExpiredOpen && (
                                    <ul>
                                        {tasksListExpired.map((task: any, index: number) => (
                                            <li
                                                className={styles['task-item']}
                                                onClick={(e) => openModalTaskEdit(task.id)}
                                            >
                                                <div className={styles['task-item-inner']}>
                                                    <div className={styles['task-item-status']}>
                                                        <div
                                                            className={`${
                                                                styles['task-item-checkbox']
                                                            } ${
                                                                task.completed == 1 &&
                                                                styles['-checked']
                                                            }`}
                                                        >
                                                            <ReactSVG src={iconCheck} />
                                                        </div>
                                                    </div>
                                                    <div className={styles['task-item-content']}>
                                                        <div className={styles['task-item-title']}>
                                                            {task.title}
                                                        </div>
                                                        <div
                                                            className={styles['task-item-subtasks']}
                                                        >
                                                            {task.subtasks.length}
                                                            {task.subtasks.length === 1
                                                                ? ' subtaak'
                                                                : ' subtaken'}{' '}
                                                        </div>
                                                        <div className={styles['task-item-time']}>
                                                            {formattedTime(
                                                                task.end_time,
                                                                'expired',
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={styles['task-item-tags']}
                                                        onClick={(e) => openModalTaskEdit(task.id)}
                                                    >
                                                        {task.labels.length > 0 &&
                                                            task.labels.map(
                                                                (label: any, index: number) => (
                                                                    <div
                                                                        className={
                                                                            styles['task-item-tag']
                                                                        }
                                                                    >
                                                                        {label.name}
                                                                    </div>
                                                                ),
                                                            )}
                                                    </div>
                                                    <div className={styles['task-item-edit']}>
                                                        <ReactSVG src={iconEllipsis} />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles['task-group']}>
                        <div
                            className={styles['task-group-header']}
                            onClick={(e) => toggleTaskGroupToday()}
                        >
                            <div className={styles['task-group-toggle']}>
                                {tasksListTodayOpen ? (
                                    <ReactSVG src={iconMinus} />
                                ) : (
                                    <ReactSVG src={iconPlus} />
                                )}
                            </div>
                            <div className={styles['task-group-title']}>{tl('Today')}</div>
                            <div
                                className={`${styles['task-group-count']} ${
                                    tasksListTodayCompletedLength == tasksListToday.length &&
                                    styles['-completed']
                                }`}
                            >
                                {tasksListTodayCompletedLength}/{tasksListToday.length}
                            </div>
                        </div>
                        <div className={styles['task-group-content']}>
                            <div className={styles['task-group-inner']}>
                                {tasksListToday.length > 0 && tasksListTodayOpen && (
                                    <ul>
                                        {tasksListToday.map((task: any, index: number) => (
                                            <li
                                                className={styles['task-item']}
                                                onClick={(e) => openModalTaskEdit(task.id)}
                                            >
                                                <div className={styles['task-item-inner']}>
                                                    <div className={styles['task-item-status']}>
                                                        <div
                                                            className={`${
                                                                styles['task-item-checkbox']
                                                            } ${
                                                                task.completed == 1 &&
                                                                styles['-checked']
                                                            }`}
                                                        >
                                                            <ReactSVG src={iconCheck} />
                                                        </div>
                                                    </div>
                                                    <div className={styles['task-item-content']}>
                                                        <div className={styles['task-item-title']}>
                                                            {task.title}
                                                        </div>
                                                        <div
                                                            className={styles['task-item-subtasks']}
                                                        >
                                                            {task.subtasks.length}
                                                            {task.subtasks.length === 1
                                                                ? ' subtaak'
                                                                : ' subtaken'}{' '}
                                                        </div>
                                                        <div className={styles['task-item-time']}>
                                                            {formattedTime(task.end_time, 'hours')}
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={styles['task-item-tags']}
                                                        onClick={(e) => openModalTaskEdit(task.id)}
                                                    >
                                                        {task.labels.length > 0 &&
                                                            task.labels.map(
                                                                (label: any, index: number) => (
                                                                    <div
                                                                        className={
                                                                            styles['task-item-tag']
                                                                        }
                                                                    >
                                                                        {label.name}
                                                                    </div>
                                                                ),
                                                            )}
                                                    </div>
                                                    <div className={styles['task-item-edit']}>
                                                        <ReactSVG src={iconEllipsis} />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={styles['task-group']}>
                        <div
                            className={styles['task-group-header']}
                            onClick={(e) => toggleTaskGroupWeek()}
                        >
                            <div className={styles['task-group-toggle']}>
                                {tasksListWeekOpen ? (
                                    <ReactSVG src={iconMinus} />
                                ) : (
                                    <ReactSVG src={iconPlus} />
                                )}
                            </div>
                            <div className={styles['task-group-title']}>{tl('This Week')}</div>
                            <div
                                className={`${styles['task-group-count']} ${
                                    tasksListWeekCompletedLength == tasksListWeek.length &&
                                    styles['-completed']
                                }`}
                            >
                                {tasksListWeekCompletedLength}/{tasksListWeek.length}
                            </div>
                        </div>
                        <div className={styles['task-group-content']}>
                            <div className={styles['task-group-inner']}>
                                {tasksListWeek.length > 0 && tasksListWeekOpen && (
                                    <ul>
                                        {tasksListWeek.map((task: any, index: number) => (
                                            <li
                                                className={styles['task-item']}
                                                onClick={(e) => openModalTaskEdit(task.id)}
                                            >
                                                <div className={styles['task-item-inner']}>
                                                    <div className={styles['task-item-status']}>
                                                        <div
                                                            className={`${
                                                                styles['task-item-checkbox']
                                                            } ${
                                                                task.completed == 1 &&
                                                                styles['-checked']
                                                            }`}
                                                        >
                                                            <ReactSVG src={iconCheck} />
                                                        </div>
                                                    </div>
                                                    <div className={styles['task-item-content']}>
                                                        <div className={styles['task-item-title']}>
                                                            {task.title}
                                                        </div>
                                                        <div
                                                            className={styles['task-item-subtasks']}
                                                        >
                                                            {task.subtasks.length}
                                                            {task.subtasks.length === 1
                                                                ? ' subtaak'
                                                                : ' subtaken'}{' '}
                                                        </div>
                                                        <div className={styles['task-item-time']}>
                                                            {formattedTime(task.end_time, 'days')}
                                                        </div>
                                                    </div>
                                                    <div className={styles['task-item-tags']}>
                                                        {task.labels.length > 0 &&
                                                            task.labels.map(
                                                                (label: any, index: number) => (
                                                                    <div
                                                                        className={
                                                                            styles['task-item-tag']
                                                                        }
                                                                    >
                                                                        {label.name}
                                                                    </div>
                                                                ),
                                                            )}
                                                    </div>
                                                    <div className={styles['task-item-edit']}>
                                                        <ReactSVG src={iconEllipsis} />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles['task-group']}>
                        <div
                            className={styles['task-group-header']}
                            onClick={(e) => toggleTaskGroupMonth()}
                        >
                            <div className={styles['task-group-toggle']}>
                                {tasksListMonthOpen ? (
                                    <ReactSVG src={iconMinus} />
                                ) : (
                                    <ReactSVG src={iconPlus} />
                                )}
                            </div>
                            <div className={styles['task-group-title']}>{tl('This Month')}</div>
                            <div
                                className={`${styles['task-group-count']} ${
                                    tasksListMonthCompletedLength == tasksListMonth.length &&
                                    styles['-completed']
                                }`}
                            >
                                {tasksListMonthCompletedLength}/{tasksListMonth.length}
                            </div>
                        </div>
                        <div className={styles['task-group-content']}>
                            <div className={styles['task-group-inner']}>
                                {tasksListMonth.length > 0 && tasksListMonthOpen && (
                                    <ul>
                                        {tasksListMonth.map((task: any, index: number) => (
                                            <li
                                                className={styles['task-item']}
                                                onClick={(e) => openModalTaskEdit(task.id)}
                                            >
                                                <div className={styles['task-item-inner']}>
                                                    <div className={styles['task-item-status']}>
                                                        <div
                                                            className={`${
                                                                styles['task-item-checkbox']
                                                            } ${
                                                                task.completed == 1 &&
                                                                styles['-checked']
                                                            }`}
                                                        >
                                                            <ReactSVG src={iconCheck} />
                                                        </div>
                                                    </div>
                                                    <div className={styles['task-item-content']}>
                                                        <div className={styles['task-item-title']}>
                                                            {task.title}
                                                        </div>
                                                        <div
                                                            className={styles['task-item-subtasks']}
                                                        >
                                                            {task.subtasks.length}
                                                            {task.subtasks.length === 1
                                                                ? ' subtaak'
                                                                : ' subtaken'}{' '}
                                                        </div>
                                                        <div className={styles['task-item-time']}>
                                                            {formattedTime(task.end_time, 'weeks')}
                                                        </div>
                                                    </div>
                                                    <div className={styles['task-item-tags']}>
                                                        {task.labels.length > 0 &&
                                                            task.labels.map(
                                                                (label: any, index: number) => (
                                                                    <div
                                                                        className={
                                                                            styles['task-item-tag']
                                                                        }
                                                                    >
                                                                        {label.name}
                                                                    </div>
                                                                ),
                                                            )}
                                                    </div>
                                                    <div className={styles['task-item-edit']}>
                                                        <ReactSVG src={iconEllipsis} />
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className={styles['add-task-container']}>
                <div
                    className={styles['add-task-button']}
                    onClick={() => setShowModalAddTask(true)}
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 64 64"
                        fill="#fff"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M58 32C58 33.1 57.1 34 56 34H34V56C34 57.1055 33.1055 58.0012 32 58.0012C30.8945 58.0012 30 57.1 30 56V34H8C6.8945 34 6 33.1062 6 32.0012C6 30.9 6.895 30 8 30H30V7.99997C30 6.89447 30.8945 6.00122 32 6.00122C33.1055 6.00122 34 6.89497 34 7.99997V30H56C57.1 30 58 30.9 58 32Z"
                            fill="#fff"
                        />
                    </svg>
                    {tl('Add')}
                </div>
            </div>

            {showPopup && <Popup callBack={() => callBackClosePopup()} />}

            {showModalAddTask && <ModalAddTask callBack={() => callBackTaskAdd()} />}
            {showModalEditTask && (
                <ModalEditTask taskId={modalEditTaskId} callBack={() => callBackTaskEdit()} />
            )}
        </>
    );
};
