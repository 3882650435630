import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

export interface SettingsProps {
    title: string;
    text: string;
    data?: any;
    link?: string;
}
export const Block = ({ title, text, link = '#' }: SettingsProps) => {
    return (
        <li className={styles['-small']}>
            <Link to={link}>
                <div className={styles['title']}>{tl(title)}</div>
                <div className={styles['count']}>{text}</div>
            </Link>
        </li>
    );
};

export const BlockGraph = ({ title, text, data, link = '#' }: SettingsProps) => {
    const [loaded, setLoaded] = useState(false);
    const options = {
        plugins: {
            title: {
                display: false,
                text: '',
            },
            legend: {
                display: false,
            },
            labels: {
                display: false,
            },
        },
        elements: {
            line: {
                tension: 0.3,
            },
        },
        bezierCurve: false,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                stacked: false,
                display: false,
                ticks: {
                    stepSize: 2,
                },
                grid: {
                    display: false,
                },
                min: 0,
            },
            x: {
                stacked: true,
                clip: true,
                display: false,
                grid: {
                    display: false,
                },
            },
        },
        animation: {
            duration: 0,
        },
    };
    const labels: any = data[0];
    const data2 = {
        labels,
        datasets: [
            {
                label: 'avg',
                backgroundColor: 'rgba(5,81,141, 0.25)',
                borderColor: 'rgba(5,81,141, 1.0)',
                pointBackgroundColor: 'rgba(5,81,141,0)',
                pointBorderWidth: 12,
                pointBorderColor: 'rgba(255,255,255,0)',
                data: data[1],
                fill: '-1',
                barThickness: 3,
                borderWidth: 1.5,
                maxBarThickness: 12,
            },
        ],
    };

    return (
        <li>
            <Link to={link}>
                <div className={styles['title']}>{tl(title)}</div>
                <div className={styles['count']}>{text}</div>
                <div className={styles['graph']}>
                    <Line options={options} data={data2} />
                </div>
            </Link>
        </li>
    );
};
