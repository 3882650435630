import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import { tl } from '../../services/language/language';
import styles from './screens.module.scss';

export const ScreenBlocked = () => {
    return (
        <div className={styles['blocked']}>
            <div className={styles['inner']}>
                <div className={styles['logo']}>
                    <ReactSVG src={logo} />
                </div>
                <p className={styles['text']}>
                    {tl('For administrative reasons, the app is not available')}.
                </p>
                <p className={styles['text']}>{tl('Please contact our support')}.</p>
            </div>
        </div>
    );
};
