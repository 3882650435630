import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { DateTime } from 'luxon';
import React from 'react';
import { Line } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    BarElement,
);

interface TemperatureData {
    date: string;
    min: number;
    max: number;
    avg: number;
}

interface Props {
    className?: string;
    reversedGroupedData: any;
    minLine: any;
    maxLine: any;
}

export const TemperatureLineChart: React.FC<Props> = ({
    className,
    reversedGroupedData,
    minLine,
    maxLine,
}) => {
    const labels: any = [];
    const dataResultsLabels: any = [];
    const dataResultsMin: any = [];
    const dataResultsAVG: any = [];
    const dataResultsMax: any = [];
    const dataResultsMinLine: any = [];
    const dataResultsMaxLine: any = [];

    var minTemp: any = maxLine;
    var maxTemp: any = maxLine;

    const graphData: any = reversedGroupedData!;

    reversedGroupedData.map(function (d: any) {
        //console.log(d);
        //const dateDay = DateTime.fromFormat(d.date, 'yyyy-MM-dd').toFormat('dd', { locale: 'nl' });
        /*const dateMonth = DateTime.fromFormat(d.date, 'yyyy-MM-dd').toFormat('MMMM', {
            locale: 'nl',
        });*/

        const dateDay = DateTime.fromFormat(d.date, 'yyyy-MM-dd HH:mm:ss').toFormat('dd', {
            locale: 'nl',
        });
        const dateMonth = DateTime.fromFormat(d.date, 'yyyy-MM-dd HH:mm:ss').toFormat('MMMM', {
            locale: 'nl',
        });
        labels.push(dateDay + ' ' + dateMonth);
        dataResultsMax.push(d.max);
        dataResultsAVG.push(d.avg);
        dataResultsMin.push(d.min);
        dataResultsMinLine.push(minLine);
        dataResultsMaxLine.push(maxLine);
        if (d.max > maxTemp) {
            maxTemp = d.max;
        }
        if (d.min < minTemp) {
            minTemp = d.min;
        }
    });
    if (minTemp > minLine) {
        minTemp = minLine;
    }
    if (maxTemp < maxLine) {
        maxTemp = maxLine;
    }
    minTemp = Math.ceil(parseInt(minTemp + -5) / 2.5) * 2.5;
    maxTemp = Math.floor(parseInt(maxTemp + 5) / 2.5) * 2.5;

    const options = {
        animation: {
            duration: 0,
        },
        plugins: {
            title: {
                display: false,
                text: '',
            },
            legend: {
                display: false,
            },
        },
        elements: {
            line: {
                tension: 0.4,
            },
        },
        bezierCurve: false,
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            y: {
                stacked: false,
                min: minTemp,
                max: maxTemp,
                ticks: {
                    // forces step size to be 50 units
                    stepSize: 5,
                },
                grid: {
                    display: false,
                },
            },
            x: {
                stacked: true,
                clip: true,
                grid: {
                    display: false,
                },
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
        },
    };
    const data = {
        labels,
        datasets: [
            {
                label: 'max',
                backgroundColor: 'rgba(240, 140, 121, 0.0)',
                borderColor: 'rgba(140, 140, 140, 0.0)',
                borderWidth: 0,
                data: dataResultsMax,
                fill: true,
                radius: 4,
                barThickness: 12,
                maxBarThickness: 12,
            },
            {
                label: 'avg',
                backgroundColor: 'rgba(5,81,141, 0.25)',
                borderColor: 'rgba(5,81,141, 1.0)',
                pointBackgroundColor: 'rgba(5,81,141,1)',
                pointBorderWidth: 12,
                pointBorderColor: 'rgba(255,255,255,0)',
                data: dataResultsAVG,
                fill: '-1',
                barThickness: 12,
                maxBarThickness: 12,
            },
            {
                label: 'min',
                backgroundColor: 'rgba(5,81,141, 0.25)',
                borderColor: 'rgba(140, 140, 140, 0.0)',
                pointBackgroundColor: 'rgba(0,0,0,0)',
                borderWidth: 0,
                data: dataResultsMin,
                fill: '-1',
                line: true,
                radius: 4,
                barThickness: 12,
                maxBarThickness: 12,
            },
            {
                label: 'max2',
                backgroundColor: 'rgba(240, 140, 121, 0.0)',
                borderColor: 'rgba(140, 140, 140, 1.0)',
                pointBackgroundColor: 'rgba(0,0,0,0)',
                pointBorderColor: 'rgba(0,0,0,0)',
                borderWidth: 1,
                data: dataResultsMaxLine,
                fill: '-1',
                radius: 4,
                borderDash: [8],
                borderDashOffset: 2.0,
            },
            {
                label: 'max2',
                backgroundColor: 'rgba(240, 140, 121, 0.0)',
                borderColor: 'rgba(140, 140, 140, 1.0)',
                pointBackgroundColor: 'rgba(0,0,0,0)',
                pointBorderColor: 'rgba(0,0,0,0)',
                borderWidth: 1,
                data: dataResultsMinLine,
                fill: '-1',
                radius: 4,
                borderDash: [8],
                borderDashOffset: 2.0,
            },
        ],
    };

    return (
        <>
            <Line options={options} data={data} />
        </>
    );
};
