import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconRotate from '../../assets/icons/rotate.svg';
import { LanguageSwitch } from '../../components/language-switch/language-switch';
import { logout, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
export interface SettingsProps {
    className?: string;
}

export const DashboardPageSettings = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const handleLogout = () => {
        logout();
        window.location.reload(); // Laad app opnieuw ivm uitloggen
    };
    return (
        <>
            <section className={styles['section-header']}>
                <div className={styles['grid']}>
                    <div className={styles['box-first']}></div>
                    <div className={styles['header-title']}>
                        <h2>{tl('Settings')}</h2>
                    </div>
                    <div className={styles['box-last']}></div>
                </div>
            </section>

            <section className={styles['section-header-filler']}></section>

            <section className={styles['section-blocks']}>
                <div className={styles['grid']}>
                    <LanguageSwitch style="relative" />
                    <Link className={styles['button']} to="/">
                        <ReactSVG src={iconRotate} />
                        {tl('To App')}
                    </Link>
                    <div className={styles['button']} onClick={() => handleLogout()}>
                        {tl('Logout')}
                    </div>
                </div>
            </section>
        </>
    );
};
