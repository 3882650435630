import axios, { AxiosError } from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState, ChangeEvent } from 'react';
import { decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import modalStyle from './modal.module.scss';
import BASE_URL from '../../services/apiConfig';
import { ModalBasic } from './basic';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Organisation } from './add-user';
import WeekSelector from '../../components/pdf/week-selector';
import styles from '../../components/modals/pdf.module.scss';

gsap.registerPlugin(CustomEase);

export interface GeneratePdfModalProps {
  callBack: () => void;
}

interface Location {
  id: number;
  organisation_id: number;
  name: string;
}

export const GeneratePdfModal = ({ callBack }: GeneratePdfModalProps) => {
  const [chosenLocations, setChosenLocations] = useState<string[]>([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<string[]>(['all']);
  const currentWeek = getCurrentWeek().toString();
  const [selectedWeek, setSelectedWeek] = useState<string>(currentWeek);

  const [emailPdfEnabledChecked, setEmailPdfEnabledChecked] = useState(false);

  const token = tokenGet();
  const validToken = tokenValid(token);
  const tokenData = tokenDecoded(token);

  var pdfEmailState = false;

  const handleEmailPdfEnabledChange = () => {
    setEmailPdfEnabledChecked(!emailPdfEnabledChecked);
  };

  useEffect(() => {
    const modalElement = document.getElementById('modal-element');
    if (modalElement) {
      openModal();
      fetchLocationsData();
    }
  }, []);
  const handleGeneratePDF = async () => {
    closeModal(callBack);
  };

  const handleLocation = (event: ChangeEvent<HTMLSelectElement>) => {
    const options = event.target.options;
    const values: string[] = [];

    for (const option of options) {
      if (option.selected) {
        values.push(option.value);
      }
    }

    if (values.includes('all')) {
      const allIds = locations.map(location => location.id.toString());
      setSelectedLocations(allIds);
    } else {
      setSelectedLocations(values);
    }
  };
  const sendEmailWithPDF = async (pdfUrl: string) => {
    try {
      const postData = JSON.stringify({
        pdf: btoa(pdfUrl),
      });

      const response = await axios.post(`${BASE_URL}/mail/send/pdf-report`, postData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log('Email sent successfully:', response.data);
    } catch (response) {
      console.log('Error sending email:', response);
    }
  };

  const fetchLocationsData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/organisation/`, {
        headers: authHeader()
      });
      var locations = response.data.results.locations;
      setLocations(locations);
    } catch (error) {
      console.log(error);
    }
  };

  const handleWeekChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedWeek(event.target.value);
  };

  const GeneratePDF = () => {
    const organizationId = tokenData.organisation_id;

    // Determine the location parameter for the URL
    const locationParameter = selectedLocations.length === 1
      ? `location_id=${selectedLocations[0]}`
      : 'location_id=all';

    const weekNumberParam = `week_number=${selectedWeek}`;

    const pdfUrl = `${BASE_URL}/report?organisation_id=${organizationId}&${locationParameter}&${weekNumberParam}`;

    window.open(pdfUrl);

    if (emailPdfEnabledChecked) {
      sendEmailWithPDF(pdfUrl);
    }
  };

  function getCurrentWeek(): number {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear = Math.floor((today.getTime() - firstDayOfYear.getTime()) / 86400000);
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  return (
    <>
      <div className={modalStyle['modal-overlay']}>
        <div
          className={modalStyle['modal-background']}
          id="modal-background"
          onClick={() => closeModal(callBack)}
        ></div>
        <div className={modalStyle['modal-element']} id="modal-element">
          <div className={modalStyle['modal-content-pdf']}>
            <div className={modalStyle['modal-title']}>
              <h3>Uitdraai data</h3>
              <h5></h5>
            </div>
            <div className={modalStyle['modal-form']}>
              <div className={modalStyle['form-group']}>
                <label htmlFor="weekSelect" className={modalStyle['inline']}>{tl('Date')}:</label>
                <WeekSelector value={selectedWeek} onChange={handleWeekChange} />
                <div className={modalStyle['form-group']}>
                  <label htmlFor="locationsSelect" className={modalStyle['inline']}>{tl('Locations')}:</label>
                  <select name="Locations" id="locationsSelect" onChange={e => setSelectedLocations([e.target.value])}>
                    <option value="all">{tl('All')}</option>
                    {locations.map(location => (
                      <option key={location.id} value={location.id.toString()}>
                        {location.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={modalStyle['form-group']}>
                <label htmlFor="repeats" className={modalStyle['inline']}>
                  {tl('Export mail')}
                </label>
                <div className={modalStyle['checkbox-container']}>
                  <div
                    className={`${modalStyle['checkbox']} ${
                      emailPdfEnabledChecked === true
                        ? modalStyle['-checked']
                        : ''
                    }`}
                    onClick={handleEmailPdfEnabledChange}
                  ></div>
                </div>
              </div>

              <div className={modalStyle['form-group']}>
              <div className={styles['pdf-container']}>
                <div
                  className={styles['pdf-button']}
                  onClick={GeneratePDF}
                >
                  {tl('Turn out')}
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeneratePdfModal;