import { useEffect, useState } from 'react';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as Yup from 'yup';
import iconLock from '../../assets/icons/lock.svg';
import logo from '../../assets/logo.svg';
import { passwordreset } from '../../services/auth-service';
import { tl } from '../../services/language/language';
import { Button } from '../button/button';
import { LanguageSwitch } from '../language-switch/language-switch';
import styles from './forms.module.scss';
export interface PasswordforgetUserProps {
    className?: string;
}

export const Passwordreset = ({ className }: PasswordforgetUserProps) => {
    const { hash } = useParams()!;
    var defaultFormValues = { password: '', password2: '' };
    const [successful, setSuccessful] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(true);
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [showErrorForm, setShowErrorForm] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<any>(defaultFormValues);
    let navigate: NavigateFunction = useNavigate();

    const updateFormValues = (name: any, value: any) => {
        var formValues2 = formValues;
        formValues2[name] = value;
        setFormValues(formValues2);
    };

    useEffect(() => {
        /* axios
            .get(`${BASE_URL}/user/passwordforget/checkhash?hash=${hash}`)
            .then((response) => {
                if (response.data.results && response.data.results.length > 0) {
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setShowErrorForm(true);
                    setMessage('Invalid hash.');
                }
            })
            .catch((error) => {
                console.error(error);
                setShowForm(false);
                setMessage('Error checking the hash.');
            });*/
    }, [hash]);

    const initialValues = {
        hash: hash,
        password: '',
        password2: '',
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, 'Het wachtwoord moet uit minstens 6 tekens bestaan')
            .max(100, 'Het wachtwoord mag maximaal 100 tekens bevatten')
            .required('Dit veld is vereist'),
        password2: Yup.string()
            .min(6, 'Het wachtwoord moet uit minstens 6 tekens bestaan')
            .max(100, 'Het wachtwoord mag maximaal 100 tekens bevatten')
            .required('Dit veld is vereist')
            .oneOf([Yup.ref('password')], 'Wachtwoorden komen niet overeen'),
    });

    const submitForm = async () => {
        var password = formValues.password;
        var password2 = formValues.password2;
        if (password == password2) {
            var hash2: any = hash;
            await passwordreset(hash2, password).then(
                () => {
                    setErrorMessage('Success!');
                },
                (error) => {
                    setErrorMessage('Error');
                },
            );
        }
    };

    const handleRegister = (
        values: any,
        { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
    ) => {
        const { hash, password, password2 } = values;
        console.log(hash);
        if (password == password2) {
            passwordreset(hash, password).then(
                (response) => {
                    setShowForm(false);
                    setShowResponse(true);
                },
                (error) => {
                    const resMessage =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();

                    //setMessage(resMessage);
                    setSuccessful(false);
                    setSubmitting(false);
                },
            );
        } else {
            setSuccessful(false);
            setSubmitting(false);
        }
    };

    return (
        <>
            <LanguageSwitch />
            <section className={styles['section-forms']}>
                <div className={styles['logo']}>
                    <img src={logo} alt="Albird" />
                </div>

                <div className={styles['box']}>
                    <div className={styles['inner']}>
                        <h4>{tl('Password reset')}</h4>
                        {errorMessage ? (
                            <>
                                <div className={styles['message']}>{tl(errorMessage)}</div>
                            </>
                        ) : (
                            <>
                                <div className={styles['form']}>
                                    <div className={styles['form-row']}>
                                        <div className={styles['icon']}>
                                            <ReactSVG src={iconLock} />
                                        </div>

                                        <input
                                            type="password"
                                            name="password"
                                            placeholder={tl('Password')}
                                            onChange={(e) =>
                                                updateFormValues('password', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className={styles['form-row']}>
                                        <div className={styles['icon']}>
                                            <ReactSVG src={iconLock} />
                                        </div>

                                        <input
                                            type="password"
                                            name="password2"
                                            placeholder={tl('Password again')}
                                            onChange={(e) =>
                                                updateFormValues('password2', e.target.value)
                                            }
                                        />
                                    </div>

                                    <Button
                                        callBack={submitForm}
                                        style=""
                                        icon=""
                                        text={tl('Reset')}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles['sub-links']}>
                        <Link to="/">{tl('Back to login')}</Link>
                    </div>
                </div>
            </section>
        </>
    );
};
