import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { ListCells } from '../components/list-cells';

export interface SettingsProps {
    className?: string;
}

export const DashboardPageOrganisationSensors = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const { id } = useParams<{ id: string }>();
    useEffect(() => {}, []);

    return (
        <>
            <ListCells organisation={id} />
        </>
    );
};
