import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconRotate from '../assets/icons/rotate.svg';
import logo from '../assets/logo.svg';
import Icon from '../components/icons/icons';
import { LanguageSwitch } from '../components/language-switch/language-switch';
import BASE_URL from '../services/apiConfig';
import { authHeader, logout, tokenDecoded, tokenGet, tokenValid } from '../services/auth';
import { tl } from '../services/language/language';
import styles from './dashboard.module.scss';
import { DashboardPageOrganisation } from './pages/organisation';
import { DashboardPageOrganisations } from './pages/organisations';
import { DashboardPageOverview } from './pages/overview';
import { DashboardPageSensors } from './pages/sensors';
import { DashboardPageSettings } from './pages/settings';
import { DashboardPageStatistics } from './pages/statistics';
import settings from './settings';
export interface LocationProps {}

export const Dashboard = ({}: LocationProps) => {
    const p: any = [
        { name: 'Overview', url: '', menu: true, icon: 'home' },
        { name: 'Organisations', url: '/organisations', menu: true, icon: 'organisations' },
        { name: 'Organisation', url: '/organisation/' },
        { name: 'Sensors', url: '/sensors', menu: true, icon: 'hardware' },
        { name: 'Statistics', url: '/statistics', menu: true, icon: 'statistics' },
        { name: 'Settings', url: '/settings', menu: true, icon: 'settings' },
    ];

    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [dashboardPage, setDashboardPage] = useState<string>('Overview');
    const [pages, setPages] = useState<any>(p);

    const fetchLocationsData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisation/`, {
                headers: authHeader(),
            });
            var locations = response.data.results.locations;
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const currentURL = window.location.pathname;

        pages.map((page: any) => {
            if (currentURL.includes(settings.BASE_URL + '' + page.url)) {
                setDashboardPage(page.name);
                console.log();
            }
        });
        window.localStorage.setItem('dashboardMode', 'false');
        setTimeout(function () {
            //window.dispatchEvent(new Event('dashboardMode'));
        }, 3000);
    }, []);

    const handleLogout = () => {
        logout();
        window.location.reload(); // Laad app opnieuw ivm uitloggen
    };

    return (
        <>
            <div className={styles['main']}>
                <div className={styles['navigation']} id="dashboard-navigation">
                    <div className={styles['logo']}>
                        <ReactSVG src={logo} />
                    </div>
                    <ul className={styles['menu']}>
                        {pages.map(
                            (page: any, i: any) =>
                                page.menu && (
                                    <li
                                        className={`${styles['menu-item']} ${
                                            dashboardPage === page.name ? styles['-active'] : ''
                                        }`}
                                    >
                                        <Link
                                            to={settings.BASE_URL + page.url}
                                            onClick={() => setDashboardPage(page.name)}
                                        >
                                            <Icon icon={page.icon} />

                                            <span>{tl(page.name)}</span>
                                        </Link>
                                    </li>
                                ),
                        )}
                    </ul>
                </div>
                <div className={styles['top-navigation']}>
                    <div className={styles['intro-text']}>
                        {tl('Welcome')}, {tokenData.firstname}
                    </div>
                    <LanguageSwitch style="relative" />
                    <Link className={`${styles['button']} ${styles['-small']}`} to="/">
                        <ReactSVG src={iconRotate} />
                        {tl('To App')}
                    </Link>
                    <div
                        className={`${styles['button']} ${styles['-small']}`}
                        onClick={() => handleLogout()}
                    >
                        {tl('Logout')}
                    </div>
                </div>

                <div className={styles['dashboard-content']}>
                    <div className={styles['dashboard-scroll']} id="dashboardScrollContainer">
                        <Routes>
                            <Route path={settings.BASE_URL} element={<DashboardPageOverview />} />
                            <Route
                                path={settings.BASE_URL + '/organisations'}
                                element={<DashboardPageOrganisations />}
                            />
                            <Route
                                path={settings.BASE_URL + '/organisation/:id'}
                                element={<DashboardPageOrganisation />}
                            />
                            <Route
                                path={settings.BASE_URL + '/sensors'}
                                element={<DashboardPageSensors />}
                            />
                            <Route
                                path={settings.BASE_URL + '/settings'}
                                element={<DashboardPageSettings />}
                            />
                            <Route
                                path={settings.BASE_URL + '/statistics'}
                                element={<DashboardPageStatistics />}
                            />
                        </Routes>
                    </div>
                </div>
            </div>
            {/*showModalEditCell && (
                    <ModalEditCell cell={currentCell} callBack={() => callbackEditCell()} />
                )*/}
        </>
    );
};
