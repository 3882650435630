import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import iconPlus from '../../assets/icons/plus.svg';
import { ModalAddSensor } from '../../components/modals/add-sensor';
import { tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import { ListCells } from '../components/list-cells';
import styles from '../dashboard.module.scss';
export interface SettingsProps {
    className?: string;
}

export const DashboardPageSensors = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [showModalAddSensor, setShowModalAddSensor] = useState(false);

    const openAddSensorModal = () => {
        setShowModalAddSensor(true);
    };
    const callbackAddSensor = async () => {
        //reload data
        //fetchCells();
        setShowModalAddSensor(false);
    };

    return (
        <>
            <section className={styles['section-header']}>
                <div className={styles['grid']}>
                    <div className={styles['box-first']}></div>
                    <div className={styles['header-title']}>
                        <h2>
                            <h2>{tl('Sensors')}</h2>
                        </h2>
                    </div>
                    <div className={styles['box-last']}>
                        <div
                            className={`${styles['header-button']} ${styles['-filled']}`}
                            onClick={() => openAddSensorModal()}
                        >
                            <span>{tl('Add sensor')}</span>
                            <ReactSVG src={iconPlus} />
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['section-header-filler']}></section>

            <ListCells />

            {showModalAddSensor && <ModalAddSensor callBack={() => callbackAddSensor()} />}
        </>
    );
};
