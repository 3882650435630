import { ReactSVG } from 'react-svg';
import logo from '../../assets/logo.svg';
import styles from './screens.module.scss';

export const ScreenSplash = () => {
    return (
        <div className={styles['splash']}>
            <div className={styles['inner']}>
                <div className={styles['logo']}>
                    <ReactSVG src={logo} />
                </div>
            </div>
        </div>
    );
};
