import React, { useState, useEffect } from 'react';

interface WeekSelectorProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const WeekSelector: React.FC<WeekSelectorProps> = ({ value, onChange }) => {
  const [weekOptions, setWeekOptions] = useState<number[]>([]);

  useEffect(() => {
    const getWeekNumber = (date: Date): number => {
      const firstDayOfYear = new Date(date.getFullYear(), 0, 1);

      const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
      return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    };


    const currentDate = new Date();
    const currentWeek = getWeekNumber(currentDate);
    const weeks: number[] = [];
    // Generate weeks for the current week and 4 weeks back
    for (let i = 0; i <= 4; i++) {
      weeks.push(currentWeek - i);
    }

    setWeekOptions(weeks);
  }, []);

  return (
    <select name="weekNumber" value={value} onChange={onChange}>
      {weekOptions.map(week => (
        <option key={week} value={week}>Week {week}</option>
      ))}
    </select>
  );
};

export default WeekSelector;
