import { logout, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
export interface SettingsProps {
    className?: string;
}

export const DashboardPageStatistics = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const handleLogout = () => {
        logout();
        window.location.reload(); // Laad app opnieuw ivm uitloggen
    };
    return (
        <>
            <section className={styles['section-header']}>
                <div className={styles['grid']}>
                    <div className={styles['box-first']}></div>
                    <div className={styles['header-title']}>
                        <h2>
                            <h2>{tl('Statistics')}</h2>
                        </h2>
                    </div>
                    <div className={styles['box-last']}></div>
                </div>
            </section>

            <section className={styles['section-header-filler']}></section>
        </>
    );
};
