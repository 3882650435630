import axios from 'axios';
import CryptoJS from 'crypto-js';
import BASE_URL from '../services/apiConfig';

export const updateToken = async (token: any) => {
    const response = await axios.get(`${BASE_URL}/token/check`, {
        headers: authHeader(),
    });
    var token = response.data.results;
    localStorage.setItem('user', token);
    return token;
};

export const tokenGet = () => {
    var token = localStorage.getItem('user')!;
    if (!token) {
        return false;
    } else {
        return token;
    }
};

export const decodeToken = (token?: any) => {
    if (!token) {
        token = tokenGet();
    }

    if (!tokenValid(token)) {
        return false;
    }
    var decrypted = tokenDecoded(token);
    return decrypted;
};

/*export const tokenValid = (token: any) => {
    if (checkV1Token(token)) {
        return true;
    } else if (checkV2Token(token)) {
        return true;
    } else {
        return false;
    }
};*/
export const checkOrganisationActive = (val: any) => {
    if (val !== 1) {
        localStorage.setItem('organisationInactive', '1');
        window.dispatchEvent(new Event('organisationInactive'));
    } else {
        localStorage.setItem('organisationInactive', '0');
        window.dispatchEvent(new Event('organisationInactive'));
    }
};

export const tokenValid = (token: any) => {
    var tokenData = tokenDecoded(token);
    if (!tokenData) {
        return false;
    }
    if (tokenData.active !== 1) {
        return false;
    }

    return true;
};

export const tokenDecoded = (token: any) => {
    if (checkV1Token(token)) {
        return JSON.parse(atob(token));
    } else if (checkV2Token(token)) {
        const secretKey: any = process.env.REACT_APP_SECRET_KEY;
        return JSON.parse(decryptStringWithKey(token, secretKey));
    } else {
        return false;
    }
};

export const authHeader = () => {
    const userStr = localStorage.getItem('user');

    if (userStr) {
        return { Authorization: userStr };
    } else {
        return { Authorization: '' };
    }
};

export const logout = () => {
    localStorage.removeItem('user');
    //window.location.reload();
};

function isJsonString(str: any) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function isAtob(str: any) {
    try {
        atob(str);
        return true;
    } catch (e: any) {
        if (e.name === 'InvalidCharacterError') {
            return false;
        }
        return false;
    }
}

export const checkV1Token = (str: any) => {
    if (isAtob(str)) {
        if (isJsonString(atob(str))) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
};
function checkV2Token(str: any) {
    const secretKey: any = process.env.REACT_APP_SECRET_KEY;
    var v2 = decryptStringWithKey(str, secretKey);
    if (v2) {
        return true;
    } else {
        return false;
    }
}

const decryptStringWithKey = (encryptedText: string, secretKey: string): string => {
    const decrypted = CryptoJS.AES.decrypt(encryptedText, secretKey).toString(CryptoJS.enc.Utf8);
    return decrypted;
};

//{"id":40,"organisation_id":"19","location_id":null,"username":"admin","email":"stefan@stefantakens.nl","firstname":"Admin","lastname":null,"active":1,"last_active":null,"created":null,"access":{"locations":3,"users":3,"tasks":3,"cells":3,"notifications":3,"organisations":3}}
