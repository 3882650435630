import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

import { useNavigate } from 'react-router-dom';
gsap.registerPlugin(CustomEase);

export interface ModalEditCellProps {
    cell: any;
    callBack: () => void;
    callBack2: () => void;
}
export interface Organisation {
    id: number;
    name: string;
}
export interface Location {
    id: number;
    name: string;
}
export const ModalEditCell = ({ cell, callBack, callBack2 }: ModalEditCellProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const navigate = useNavigate();
    var notificationsState = false;
    if (cell.notifications_enabled == '1') {
        notificationsState = true;
    }

    const [locations, setLocations] = useState<Location[]>([]);
    const [locationId, setLocationId] = useState(cell.location_id);
    const [initialL, setInitialL] = useState(true);
    const [listAllOrganisations, setListAllOrganisations] = useState(false);
    const [organisationId, setOrganisationId] = useState(cell.organisation_id);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);

    const [cellName, setName] = useState('' + cell.name + '');
    const [notificationsEnabledChecked, setNotificationsEnabledChecked] =
        useState(notificationsState);
    const [deviationMin, setDeviationMin] = useState('' + cell.notification_deviation_min + '');
    const [deviationMax, setDeviationMax] = useState('' + cell.notification_deviation_max + '');
    const [minimalTime, setMinimalTime] = useState(3600);

    const handleNotificationsEnabled = () => {
        var n = notificationsEnabledChecked;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setNotificationsEnabledChecked(n);
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };

    const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^-0-9]/g, '');
        setDeviationMin(value);
    };

    const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.replace(/[^-0-9]/g, '');
        setDeviationMax(value);
    };

    const handleLocationChange = (e: any) => {
        setLocationId(e);
    };
    const handleOrganisationChange = (e: any) => {
        var i: any = parseInt(e);
        setOrganisationId(i);
    };

    useEffect(() => {
        if (tokenData.access.organisations === 3) {
            //setListAllOrganisations(true);
        }
    }, []);

    useEffect(() => {
        //if (organisationId !== ) {
        //  console.log('joeee' + organisationId);
        //handleLocationChange(response.data.results[0].id);
        //}
        getLocations();
    }, [organisationId]);

    useEffect(() => {
        if (listAllOrganisations) {
            //getOrganisations();
        } else {
        }
    }, [listAllOrganisations]);

    useEffect(() => {
        //setLocationId(cell.location_id);
    }, [locations]);

    const getLocations = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/organisation/` + organisationId + `/locations`,
                {
                    headers: authHeader(),
                },
            );

            setLocations(response.data.results);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };

    /* const getOrganisations = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisations/`, {
                headers: authHeader(),
            });
            //console.log(response.data.results);
            setOrganisations(response.data.results);

            //handleLocationChange(response.data.results[0].id);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    }; */

    const buttonActionSubmit = async () => {
        var notificationsEnabled = 0;
        if (notificationsEnabledChecked) {
            notificationsEnabled = 1;
        }

        try {
            const updatedData = {
                cellName,
                locationId,
                organisationId,
                notificationsEnabled,
                deviationMin,
                deviationMax,
                minimalTime,
            };

            await axios.put(`${BASE_URL}/cell/${cell.id}`, updatedData, {
                headers: authHeader(),
            });

            closeModal(callBack);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };
    const buttonActionRemove = async () => {
        closeModal(callBack2);
    };

    useEffect(() => {
        openModal();
    });

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Fridge')}</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Name')}:</label>
                                <input type="text" value={cellName} onChange={handleNameChange} />
                            </div>
                            {listAllOrganisations && (
                                <div className={modalStyle['form-group']}>
                                    <label htmlFor="">{tl('Organisation')}:</label>
                                    <select
                                        name="organisation"
                                        onChange={(e) => handleOrganisationChange(e.target.value)}
                                        value={organisationId}
                                    >
                                        {organisations.map((item: Organisation) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Located at')}</label>
                                <select
                                    name="location"
                                    onChange={(e) => handleLocationChange(e.target.value)}
                                    value={locationId}
                                >
                                    {locations.map((location: Location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats">{tl('Min temperature')}</label>
                                <input
                                    type="text"
                                    placeholder={
                                        tl('Min temperature') +
                                        ` (${cell.notification_deviation_min})`
                                    }
                                    value={deviationMin}
                                    onChange={handleMinChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats">{tl('Max temperature')}</label>
                                <input
                                    type="text"
                                    placeholder={
                                        tl('Max temperature') +
                                        ` (${cell.notification_deviation_max})`
                                    }
                                    value={deviationMax}
                                    onChange={handleMaxChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    {tl('Notifications')}
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            notificationsEnabledChecked === true
                                                ? modalStyle['-checked']
                                                : ''
                                        }`}
                                        onClick={handleNotificationsEnabled}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionRemove}
                            style="red"
                            icon="remove"
                            text={tl('Delete')}
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="check"
                            text={tl('Save')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
