import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import { tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

gsap.registerPlugin(CustomEase);

export interface ModalProps {
    callBack: () => void;
}

export const ModalAddTaskTemplate = ({ callBack }: ModalProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);

    const [pageTitle, setPageTitle] = useState('Add task');
    const [templateActive, setTemplateActive] = useState('default');
    const [templateTypes, setTemplateTypes]: any = useState([
        {
            name: 'Default',
        },
        {
            name: 'Templates',
        },
    ]);
    const [templateTypeActive, setTemplateTypeActive] = useState('Default');
    const [templateLevelActive, setTemplateLevelActive] = useState('Base');

    const templates: any = [
        { name: 'Ingang Controle', slug: 'ingang-controle', tag: 'Preview' },
        { name: 'Terug Koelen', slug: 'terug-koelen', tag: 'Preview' },
        {
            name: 'Kern temperatuur gegaard product',
            slug: 'kern-temperatuur-gegaard-product',
            tag: 'HACCP',
        },
        {
            name: 'Kern temperatuur geregenereerd component',
            slug: 'kern-temperatuur-geregenereerd-component',
            tag: 'HACCP',
        },
        {
            name: 'Kern temperatuur product in warmhoudvoorziening',
            slug: 'kern-temperatuur0product-in-warmhoudvoorziening',
            tag: 'HACCP',
        },
        { name: 'Frituur temperatuur', slug: 'frituur-temperatuur', tag: 'HACCP' },
        { name: 'Ingang Controle', slug: 'ingang-controle', tag: 'Preview' },
        { name: 'Terug Koelen', slug: 'terug-koelen', tag: 'Preview' },
        {
            name: 'Kern temperatuur gegaard product',
            slug: 'kern-temperatuur-gegaard-product',
            tag: 'HACCP',
        },
        {
            name: 'Kern temperatuur geregenereerd component',
            slug: 'kern-temperatuur-geregenereerd-component',
            tag: 'HACCP',
        },
        {
            name: 'Kern temperatuur product in warmhoudvoorziening',
            slug: 'kern-temperatuur0product-in-warmhoudvoorziening',
            tag: 'HACCP',
        },
        { name: 'Frituur temperatuur', slug: 'frituur-temperatuur', tag: 'HACCP' },
    ];

    const setTemplateType = (name: any) => {
        setTemplateTypeActive(name);
    };

    const setIndividualTemplate = (t: any) => {
        setTemplateLevelActive('Sub');
        setTemplateActive(t.slug);
        setPageTitle(t.name);
    };

    const buttonActionClose = () => {
        closeModal(callBack);
    };

    const buttonActionSubmit = async () => {};
    const buttonActionDelete = () => {
        closeModal(callBack);
    };

    useEffect(() => {}, []);

    useEffect(() => {
        openModal();
    });

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl(pageTitle)}</h3>

                            {templateLevelActive === 'Base' && (
                                <ul className={modalStyle['modal-title-tabs']}>
                                    {templateTypes.map((tti: any, i: any) => (
                                        <li
                                            onClick={() => setTemplateType(tti.name)}
                                            className={`${modalStyle['modal-title-tab']} ${
                                                templateTypeActive === tti.name
                                                    ? modalStyle['-active']
                                                    : ''
                                            }`}
                                        >
                                            <span>{tl(tti.name)}</span>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>

                        {templateLevelActive === 'Base' ? (
                            templateTypeActive === 'Default' ? (
                                <>
                                    <div className={modalStyle['modal-form-templates']}>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-big']}`}
                                        >
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-big']}`}
                                            >
                                                <input
                                                    type="text"
                                                    placeholder={tl(
                                                        'Enter the name of the task here',
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-textarea']}`}
                                        >
                                            <label htmlFor="">{tl('Task description')}:</label>
                                            <div className={`${modalStyle['textarea']}`}>
                                                <textarea name="" id=""></textarea>
                                            </div>
                                        </div>
                                        <div className={`${modalStyle['field']}`}>
                                            <label htmlFor="">{tl('Period')}:</label>
                                            <div className={`${modalStyle['input']}`}>
                                                <select name="" id="">
                                                    <option value="Sligro">
                                                        {tl('Every 3 weeks')}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={`${modalStyle['field']}`}>
                                            <label htmlFor="">{tl('Start date')}:</label>
                                            <div className={`${modalStyle['input']}`}>
                                                <select name="" id="">
                                                    <option value="Kip / Wild / Gevolgte">
                                                        24 mei 2024
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : templateTypeActive === 'Templates' ? (
                                <ul className={modalStyle['modal-template-list']}>
                                    {templates.map((t: any, i: any) => (
                                        <li key={i} onClick={() => setIndividualTemplate(t)}>
                                            <div className={modalStyle['inner']}>
                                                <h4>{t.name}</h4>
                                                <div className={modalStyle['tag']}>{t.tag}</div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : null
                        ) : templateLevelActive === 'Sub' ? (
                            <>
                                {templateActive === 'ingang-controle' ? (
                                    <>
                                        <div className={modalStyle['modal-form-templates']}>
                                            <div
                                                className={`${modalStyle['field']} ${modalStyle['-field-big']}`}
                                            >
                                                <div
                                                    className={`${modalStyle['input']} ${modalStyle['-input-big']}`}
                                                >
                                                    <input
                                                        type="text"
                                                        placeholder={tl(
                                                            'Fill the product name in here',
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className={`${modalStyle['field']}`}>
                                                <label htmlFor="">{tl('Supplier')}:</label>
                                                <div className={`${modalStyle['input']}`}>
                                                    <select name="" id="">
                                                        <option value="Sligro">Sligro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className={`${modalStyle['field']}`}>
                                                <label htmlFor="">{tl('Category')}:</label>
                                                <div className={`${modalStyle['input']}`}>
                                                    <select name="" id="">
                                                        <option value="Kip / Wild / Gevolgte">
                                                            Kip / Wild / Gevolgte
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div
                                                className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                            >
                                                <label htmlFor="">{tl('Core temperature')}:</label>
                                                <div
                                                    className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                                >
                                                    <div className={`${modalStyle['icon']}`}>
                                                        <svg
                                                            width="13"
                                                            height="24"
                                                            viewBox="0 0 13 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                                fill="#A8A8A8"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className={`${modalStyle['input-enhanced']}`}
                                                        placeholder={tl('0')}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                            >
                                                <label htmlFor="">{tl('Core temperature')}:</label>
                                                <div
                                                    className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                                >
                                                    <div className={`${modalStyle['icon']}`}>
                                                        <svg
                                                            width="13"
                                                            height="24"
                                                            viewBox="0 0 13 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                                fill="#A8A8A8"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <input
                                                        type="text"
                                                        className={`${modalStyle['input-enhanced']}`}
                                                        placeholder={tl('0')}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className={`${modalStyle['field']} ${modalStyle['-field-textarea']}`}
                                            >
                                                <label htmlFor="">{tl('Comment')}:</label>
                                                <div className={`${modalStyle['textarea']}`}>
                                                    <textarea name="" id=""></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : templateActive === 'terug-koelen' ? (
                                    <div className={modalStyle['modal-form-templates']}>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-big']}`}
                                        >
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-big']}`}
                                            >
                                                <input
                                                    type="text"
                                                    placeholder={tl(
                                                        'Fill the product name in here',
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${modalStyle['field']}`}>
                                            <label htmlFor="">{tl('Date')}:</label>
                                            <div className={`${modalStyle['input']}`}>
                                                <select name="" id="">
                                                    <option value="Sligro">24/05/2024</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={`${modalStyle['field']}`}>
                                            <label htmlFor="">{tl('Start time')}:</label>
                                            <div className={`${modalStyle['input']}`}>
                                                <select name="" id="">
                                                    <option value="Kip / Wild / Gevolgte">
                                                        12:03
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                        >
                                            <label htmlFor="">{tl('Core temperature')}:</label>
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                            >
                                                <div className={`${modalStyle['icon']}`}>
                                                    <svg
                                                        width="13"
                                                        height="24"
                                                        viewBox="0 0 13 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                            fill="#A8A8A8"
                                                        />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${modalStyle['input-enhanced']}`}
                                                    placeholder={tl('0')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                        >
                                            <label htmlFor="">
                                                {tl('Core temperature after 2 hours')}:
                                            </label>
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                            >
                                                <div className={`${modalStyle['icon']}`}>
                                                    <svg
                                                        width="13"
                                                        height="24"
                                                        viewBox="0 0 13 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                            fill="#A8A8A8"
                                                        />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${modalStyle['input-enhanced']}`}
                                                    placeholder={tl('0')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                        >
                                            <label htmlFor="">
                                                {tl('Core temperature after 5 hours')}:
                                            </label>
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                            >
                                                <div className={`${modalStyle['icon']}`}>
                                                    <svg
                                                        width="13"
                                                        height="24"
                                                        viewBox="0 0 13 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                            fill="#A8A8A8"
                                                        />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${modalStyle['input-enhanced']}`}
                                                    placeholder={tl('0')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-textarea']}`}
                                        >
                                            <label htmlFor="">{tl('Comment')}:</label>
                                            <div className={`${modalStyle['textarea']}`}>
                                                <textarea name="" id=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                ) : templateActive === 'kern-temperatuur-gegaard-product' ? (
                                    <div className={modalStyle['modal-form-templates']}>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-big']}`}
                                        >
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-big']}`}
                                            >
                                                <input
                                                    type="text"
                                                    placeholder={tl(
                                                        'Fill the product name in here',
                                                    )}
                                                />
                                            </div>
                                        </div>
                                        <div className={`${modalStyle['field']}`}>
                                            <label htmlFor="">{tl('Date')}:</label>
                                            <div className={`${modalStyle['input']}`}>
                                                <select name="" id="">
                                                    <option value="Sligro">24/05/2024</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={`${modalStyle['field']}`}>
                                            <label htmlFor="">{tl('Time')}:</label>
                                            <div className={`${modalStyle['input']}`}>
                                                <select name="" id="">
                                                    <option value="Kip / Wild / Gevolgte">
                                                        12:03
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                        >
                                            <label htmlFor="">{tl('Core temperature')}:</label>
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                            >
                                                <div className={`${modalStyle['icon']}`}>
                                                    <svg
                                                        width="13"
                                                        height="24"
                                                        viewBox="0 0 13 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                            fill="#A8A8A8"
                                                        />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${modalStyle['input-enhanced']}`}
                                                    placeholder={tl('0')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                        >
                                            <label htmlFor="">
                                                {tl('Core temperature after 2 hours')}:
                                            </label>
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                            >
                                                <div className={`${modalStyle['icon']}`}>
                                                    <svg
                                                        width="13"
                                                        height="24"
                                                        viewBox="0 0 13 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                            fill="#A8A8A8"
                                                        />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${modalStyle['input-enhanced']}`}
                                                    placeholder={tl('0')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-small']}`}
                                        >
                                            <label htmlFor="">
                                                {tl('Core temperature after 5 hours')}:
                                            </label>
                                            <div
                                                className={`${modalStyle['input']} ${modalStyle['-input-enhanced']}`}
                                            >
                                                <div className={`${modalStyle['icon']}`}>
                                                    <svg
                                                        width="13"
                                                        height="24"
                                                        viewBox="0 0 13 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M8.26463 17.9894C8.26463 19.2338 7.25504 20.2434 6.01066 20.2434C4.76629 20.2434 3.7567 19.2338 3.7567 17.9894C3.7567 17.009 4.38452 16.1755 5.21708 15.8646L5.25934 9.72491C5.25934 9.30816 5.59392 8.97359 5.9684 8.97359C6.34289 8.97359 6.71972 9.30816 6.71972 9.72491L6.71975 15.8623C7.63539 16.1769 8.26463 17.008 8.26463 17.9894ZM10.5186 14.0168C11.453 15.0734 12.0212 16.4633 12.0212 17.9894C12.0212 21.3117 9.33291 24 6.05293 24H5.9684C2.664 23.9765 -0.018685 21.2586 9.80348e-05 17.9613C0.00596773 16.4469 0.575328 15.0677 1.46048 14.0168V4.46566C1.46048 1.97691 3.43739 0 5.92614 0C8.41489 0 10.5186 1.97691 10.5186 4.46566V14.0168ZM10.5186 17.9894C10.5186 16.1168 9.44467 15.072 9.05821 14.585V4.46566C9.05821 2.81041 7.70818 1.46038 6.09519 1.46038C4.4822 1.46038 3.00538 2.81041 3.00538 4.46566V14.585C2.55928 15.0837 1.51448 16.1111 1.545 17.966C1.491 20.4406 3.50313 22.4786 5.98249 22.4974H6.01184C8.49472 22.4974 10.5186 20.4735 10.5186 17.9894Z"
                                                            fill="#A8A8A8"
                                                        />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="text"
                                                    className={`${modalStyle['input-enhanced']}`}
                                                    placeholder={tl('0')}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={`${modalStyle['field']} ${modalStyle['-field-textarea']}`}
                                        >
                                            <label htmlFor="">{tl('Comment')}:</label>
                                            <div className={`${modalStyle['textarea']}`}>
                                                <textarea name="" id=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : null}
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        {/*<ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text={tl('Cancel')}
                    />*/}
                    </div>
                </div>
            </div>
        </>
    );
};
