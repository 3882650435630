import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconPlus from '../../assets/icons/plus.svg';
import iconSearch from '../../assets/icons/search.svg';
import { ModalAddOrganisation } from '../../components/modals/add-organisation';
import { TimeAgoFormatted } from '../../functions/time/time';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';

export interface SettingsProps {
    className?: string;
}

export const DashboardPageOrganisations = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [dataLoaded, setDataLoaded] = useState(false);

    const [data, setData] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);
    const [orderBy, setOrderBy] = useState('id');
    const [order, setOrder] = useState('DESC');
    const [search, setSearch] = useState('');
    const [showModalAddOrganisation, setShowModalAddOrganisation] = useState(false);
    useEffect(() => {
        const fetchOrganisations = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/organisations`, {
                    headers: authHeader(),
                    /*params: {
                        orderBy: orderBy,
                        order: order,
                    },*/
                });
                var data: any = response.data.results;

                data.forEach((org: any) => {
                    if (org.organisation_last_active === null) {
                        org.organisation_last_active = 0;
                    }
                });
                setData(data);
                setFilteredData(data);
                setDataLoaded(true);
            } catch (error) {
                console.error('Failed to fetch organisations:', error);
            }
        };

        fetchOrganisations();
    }, []);

    useEffect(() => {
        if (dataLoaded) {
            changeOrder();
        }
    }, [orderBy, order, dataLoaded]);

    useEffect(() => {
        if (dataLoaded) {
            if (search != '') {
                const data2 = data.filter((item: any) =>
                    item.name.toLowerCase().startsWith(search.toLowerCase()),
                );
                setFilteredData(data2);
            } else {
                setFilteredData(data);
            }
        }
    }, [search]);

    const handleOrder = (type: string) => {
        setOrder(type);
    };

    const handleOrderBy = (type: string) => {
        setOrderBy(type);
    };

    const handleHeadOrder = (type: string) => {
        if (type == orderBy) {
            if (order == 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        } else {
            setOrderBy(type);
        }
    };

    const changeOrder = () => {
        var tempData = filteredData.slice();

        var number = false;

        if (typeof tempData[0][orderBy] === 'number') {
            number = true;
        }

        if (order == 'ASC') {
            if (number) {
                tempData.sort((a: any, b: any) => {
                    return a[orderBy] - b[orderBy];
                });
            } else {
                tempData.sort((a: any, b: any) => a[orderBy].localeCompare(b[orderBy]));
            }
        } else {
            if (number) {
                tempData.sort((a: any, b: any) => {
                    return b[orderBy] - a[orderBy];
                });
            } else {
                tempData.sort((a: any, b: any) => b[orderBy].localeCompare(a[orderBy]));
            }
        }

        setFilteredData(tempData);
    };

    const openAddOrganisationModal = () => {
        setShowModalAddOrganisation(true);
    };
    const callbackAddOrganisation = async () => {
        //reload data
        //fetchCells();
        setShowModalAddOrganisation(false);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearch(value);
    };

    return (
        <>
            <section className={styles['section-header']}>
                <div className={styles['grid']}>
                    <div className={styles['box-first']}>
                        {/*<Link className={styles['header-button']} to={settings.BASE_URL + '/'}>
                            <ReactSVG src={iconArrowBack} />
                            <span>{tl('Back to home')}</span>
    </Link>*/}
                    </div>
                    <div className={styles['header-title']}>
                        <h2>{tl('Organisations')}</h2>
                    </div>
                    <div className={styles['box-last']}>
                        <div
                            className={`${styles['header-button']} ${styles['-filled']}`}
                            onClick={() => openAddOrganisationModal()}
                        >
                            <span>{tl('Add organisation')}</span>
                            <ReactSVG src={iconPlus} />
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['section-header-filler']}></section>

            <section className={styles['section-overview-content']}>
                <div className={styles['grid']}>
                    <>
                        <div className={styles['bar']}>
                            <div className={styles['search']}>
                                <div className={styles['search-box']}>
                                    <ReactSVG src={iconSearch} />
                                    <input
                                        type="text"
                                        placeholder={tl('Search') + '...'}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                            </div>
                            <div className={styles['filter']}>
                                <div className={styles['filter-box']}>
                                    <span>{tl('Sort by')}:</span>
                                    <select
                                        name="sort-by"
                                        id="sort-by"
                                        onChange={(e) => handleOrderBy(e.target.value)}
                                    >
                                        <option value="id">{tl('Recent')}</option>
                                        <option value="name">{tl('Name')}</option>
                                        <option value="cells_offline">{tl('Offline')}</option>
                                        <option value="cell_count">{tl('Fridges')}</option>
                                        <option value="organisation_last_active">
                                            {tl('Last active')}
                                        </option>
                                    </select>
                                    <select
                                        name="sort-by"
                                        id="sort-by"
                                        onChange={(e) => handleOrder(e.target.value)}
                                    >
                                        <option value="DESC">{tl('Descending')}</option>
                                        <option value="ASC">{tl('Ascending')}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className={`${styles['list']} ${styles['-list-organisations']}`}>
                            <div className={styles['list-head']}>
                                <div
                                    className={`${styles['column']} ${styles['-organisations-1']}`}
                                    onClick={(e) => handleHeadOrder('name')}
                                >
                                    {tl('Name')}:
                                </div>
                                <div
                                    className={`${styles['column']} ${styles['-organisations-2']}`}
                                    onClick={(e) => handleHeadOrder('organisation_last_active')}
                                >
                                    {tl('Last active')}:
                                </div>
                                <div
                                    className={`${styles['column']} ${styles['-organisations-3']}`}
                                    onClick={(e) => handleHeadOrder('cells_offline')}
                                >
                                    {tl('Status')}:
                                </div>
                            </div>
                            {filteredData.map((item: any) => (
                                <li className={styles['item']}>
                                    <Link to={`/dashboard/organisation/` + item.id + ``}>
                                        <div
                                            className={`${styles['column']} ${styles['-organisations-1']}`}
                                        >
                                            <div className={styles['title']}>{item.name}</div>
                                            <div className={styles['sub']}>
                                                {item.cell_count}

                                                {' ' + tl('Fridges')}
                                            </div>
                                        </div>
                                        <div
                                            className={`${styles['column']} ${styles['-organisations-2']}`}
                                        >
                                            <div className={styles['sub']}>
                                                {TimeAgoFormatted(item.organisation_last_active)}
                                            </div>
                                        </div>
                                        <div
                                            className={`${styles['column']} ${styles['-warning-box']} ${styles['-organisations-3']}`}
                                        >
                                            {item.cells_offline >= 1 ? (
                                                <>
                                                    <div className={styles['text']}>
                                                        <svg
                                                            width="24"
                                                            height="22"
                                                            viewBox="0 0 24 22"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M23.732 18.5469L13.7336 1.48438C12.9681 0.171875 11.0364 0.171875 10.2658 1.48438L0.272043 18.5469C-0.497176 19.8547 0.460902 21.5 2.00267 21.5H21.9995C23.5352 21.5 24.4961 19.8594 23.732 18.5469ZM10.8742 6.875C10.8742 6.25391 11.3781 5.75 11.9992 5.75C12.6203 5.75 13.1242 6.25625 13.1242 6.875V12.875C13.1242 13.4961 12.6203 14 12.0414 14C11.4625 14 10.8742 13.4984 10.8742 12.875V6.875ZM11.9992 18.5C11.1855 18.5 10.5255 17.84 10.5255 17.0262C10.5255 16.2125 11.185 15.5525 11.9992 15.5525C12.8134 15.5525 13.473 16.2125 13.473 17.0262C13.4711 17.8391 12.8149 18.5 11.9992 18.5Z"
                                                                fill="#ffffff"
                                                            />
                                                        </svg>
                                                        {item.cells_offline}
                                                        <span>{tl('offline')}</span>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div
                                                        className={`${styles['text']} ${styles['-green']}`}
                                                    >
                                                        <svg
                                                            width="64"
                                                            height="64"
                                                            viewBox="0 0 64 64"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M59.4125 12.5875C60.2 13.3625 60.2 14.6375 59.4125 15.4125L25.4125 49.4125C24.6375 50.2 23.3625 50.2 22.5875 49.4125L4.58575 31.4125C3.80475 30.6375 3.80475 29.3625 4.58575 28.5875C5.36625 27.8 6.6325 27.8 7.41375 28.5875L24 45.175L56.5875 12.5875C57.3625 11.805 58.6375 11.805 59.4125 12.5875Z"
                                                                fill="#ffffff"
                                                            />
                                                        </svg>

                                                        {/*item.cells_offline*/}
                                                        <span>{tl('online')}</span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </Link>
                                </li>
                            ))}
                        </div>
                    </>
                </div>
            </section>
            {showModalAddOrganisation && (
                <ModalAddOrganisation callBack={() => callbackAddOrganisation()} />
            )}
        </>
    );
};
