import languageFile from './language.json';

export const tl = (words: any) => {
    var languagefile: any = languageFile;
    var lang: any = '';
    var setLang = localStorage.getItem('lang');

    if (setLang !== null) {
        lang = setLang;
    } else {
        lang = 'nl';
    }

    if (languagefile[words]) {
        var returnWords = languagefile[words][lang];
    } else {
        var returnWords = words;
    }
    return returnWords;
};
