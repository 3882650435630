export interface SettingsProps {
    settings?: any;
    callBack?: () => void;
}

export const ListSort = ({ settings, callBack }: SettingsProps) => {
    //console.log(settings);

    return <></>;
};
