import { gsap, Power3 } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import iconCheck from '../../assets/icons/check.svg';
import iconLocation from '../../assets/icons/location.svg';
import iconLock from '../../assets/icons/lock.svg';
import iconClose from '../../assets/icons/menu-close.svg';
import iconPencil from '../../assets/icons/pencil.svg';
import iconPlus from '../../assets/icons/plus.svg';
import iconRotate from '../../assets/icons/rotate.svg';
import iconRemove from '../../assets/icons/trash.svg';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface ModalProps {
    className?: string;
}

export const openModal = () => {
    const dashboardNavigationEl: Element = document.getElementById('dashboard-navigation')!;
    const modalEl: Element = document.getElementById('modal-overlay')!;
    const modalBackgroundEl: Element = document.getElementById('modal-background')!;
    const modalElementEl: Element = document.getElementById('modal-element')!;
    var winW = window.innerWidth;
    var duration = 800;
    //gsap.set(modalEl, { opacity: 1, 'pointer-events': 'all' });
    if (winW < 767) {
        gsap.to(dashboardNavigationEl, {
            pointerEvents: 'none',
            opacity: 0,
            duration: duration / 4000,
            ease: Power3.easeInOut,
        });
    }

    gsap.to(modalBackgroundEl, {
        opacity: 1,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
    gsap.to(modalElementEl, {
        y: '0%',
        scale: 1,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
};
const callBack2 = () => {};
export const closeModal = (callBack: any) => {
    /*Promise.all([callBack(), callBack2()])
        .then(() => {*/
    const dashboardNavigationEl: Element = document.getElementById('dashboard-navigation')!;
    const modalEl: Element = document.getElementById('modal-overlay')!;
    const modalBackgroundEl: Element = document.getElementById('modal-background')!;
    const modalElementEl: Element = document.getElementById('modal-element')!;
    var winW = window.innerWidth;

    var duration = 600;
    gsap.to(modalBackgroundEl, {
        opacity: 0,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
    gsap.to(modalElementEl, {
        y: '100%',
        scale: 1,
        duration: duration / 1000,
        ease: Power3.easeInOut,
    });
    if (winW < 767) {
        gsap.to(dashboardNavigationEl, {
            opacity: 1,
            pointerEvents: 'all',
            delay: (duration - 200) / 1000,
            duration: 0,
            ease: Power3.easeInOut,
        });
    }
    setTimeout(function () {
        callBack();
    }, duration);
    /* })
        .catch((error) => {
            console.error('Error fetching data:', error);
        });*/
};
export interface ModalButtonProps {
    callBack: () => void;
    style: string;
    icon: string;
    text: string;
}

export const ModalButton = ({ callBack, style, icon, text }: ModalButtonProps) => {
    const [loaderVisible, setLoaderVisible] = useState(false);
    var svg = '';
    if (icon == 'remove') {
        svg = iconRemove;
    } else if (icon == 'rotate') {
        svg = iconRotate;
    } else if (icon == 'plus') {
        svg = iconPlus;
    } else if (icon == 'pencil') {
        svg = iconPencil;
    } else if (icon == 'close') {
        svg = iconClose;
    } else if (icon == 'check') {
        svg = iconCheck;
    } else if (icon == 'location') {
        svg = iconLocation;
    } else if (icon == 'lock') {
        svg = iconLock;
    }
    const onclick = async () => {
        setLoaderVisible(true);
        //setTimeout(function () {

        //await new Promise((resolve) => setTimeout(resolve, 3 * 1000));
        await callBack();
        setLoaderVisible(false);
        //}, 1200);
    };

    return (
        <button className={`${modalStyle['modal-button']} ${modalStyle[style]}`} onClick={onclick}>
            <div className={modalStyle['icon']}>
                <ReactSVG src={svg} />
            </div>
            {loaderVisible ? (
                <div className={modalStyle['loader']}>
                    <svg
                        version="1.1"
                        id="L9"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 100 100"
                        enableBackground="new 0 0 0 0"
                    >
                        <path
                            fill="#fff"
                            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                        >
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite"
                            />
                        </path>
                    </svg>
                </div>
            ) : (
                <>
                    <div className={modalStyle['text']}>{text}</div>
                </>
            )}
        </button>
    );
};
