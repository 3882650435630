import axios from 'axios';
import { useEffect, useState } from 'react';
import { CardNotification } from '../../components/cards/card-notification';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ModalNotification } from '../../components/modals/notification';
import { NotificationsOverview } from '../../components/notifications-overview/notifications-overview';
import BASE_URL from '../../services/apiConfig';
import { authHeader } from '../../services/auth';
import { tl } from '../../services/language/language';

export interface NotificationsProps {}

interface username {
    firstname: string;
    lastname: string;
}

export const Notifications = ({}: NotificationsProps) => {
    const [notificationActiveData, setNotificationActiveData] = useState<any>([]);
    const [notificationInActiveData, setNotificationInActiveData] = useState<any>([]);
    const [currentNotification, setCurrentNotification] = useState<any>();

    const [showModalNotification, setShowModalNotification] = useState(false);

    const fetchActiveNotifications = async () => {
        try {
            const activeNotificationsResponse = await axios.get(
                `${BASE_URL}/notifications/active`,
                {
                    headers: authHeader(),
                },
            );

            const notifications2: any = activeNotificationsResponse.data.results;

            setNotificationActiveData(notifications2);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchInactiveNotifications = async () => {
        try {
            const inactiveNotificationsResponse = await axios.get(
                `${BASE_URL}/notifications/inactive`,
                {
                    headers: authHeader(),
                },
            );

            const notifications2: any = inactiveNotificationsResponse.data.results;

            setNotificationInActiveData(notifications2);
        } catch (error) {
            console.error('Error fetching inactive notifications:', error);
        }
    };

    useEffect(() => {
        fetchActiveNotifications();
        fetchInactiveNotifications();
    }, []);

    const openNotificationModal = (notification: any) => {
        setCurrentNotification(notification);
        setShowModalNotification(true);
    };

    const callbackNotification = async () => {
        await Promise.all([fetchInactiveNotifications(), fetchActiveNotifications()]);
        setTimeout(function () {
            setShowModalNotification(false);
        }, 600);
    };

    return (
        <>
            <HeaderTitle
                title={tl('Notifications')}
                description={tl('Active notifications') + ': ' + notificationActiveData.length}
            />

            <NotificationsOverview title={tl('Active notifications')}>
                {notificationActiveData.map((notification: any) => (
                    <CardNotification
                        notification={notification}
                        callBack={() => openNotificationModal(notification)}
                    />
                ))}
            </NotificationsOverview>

            <NotificationsOverview title={tl('Inactive notifications')}>
                {notificationInActiveData.map((notification: any) => (
                    <CardNotification
                        notification={notification}
                        callBack={() => openNotificationModal(notification)}
                    />
                ))}
            </NotificationsOverview>

            {showModalNotification && (
                <ModalNotification
                    notification={currentNotification}
                    callBack={() => callbackNotification()}
                />
            )}
        </>
    );
};
