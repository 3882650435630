export const BatteryFormatted = (voltage: any) => {
    var maxVoltage = 3.2;
    var minVoltage = 2.5;
    var range = maxVoltage - minVoltage;
    var pointment = voltage - minVoltage;
    var batteryPerc = 0;
    if (!voltage) {
        return '-';
    }
    if (pointment > 0) {
        batteryPerc = Math.round((pointment / range) * 100);
    } else {
        batteryPerc = 0;
    }

    return batteryPerc + '%';
};
