import classNames from 'classnames';
//import jwt from 'jsonwebtoken';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconCheckList from '../../assets/icons/check-list.svg';
import iconSnowFlake from '../../assets/icons/snowflake.svg';
import iconTriangle from '../../assets/icons/triangle.svg';
import iconWrench from '../../assets/icons/wrench.svg';
import BASE_URL from '../../services/apiConfig';
import { authHeader, checkOrganisationActive, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from './menu.module.scss';
export interface MenuProps {
    className?: string;
    setActiveComponent: React.Dispatch<React.SetStateAction<string>>;
}

export const Menu = ({ className, setActiveComponent }: MenuProps) => {
    const [activeButton, setActiveButton] = useState<string>('');

    const token = localStorage.getItem('user')!;
    const tokenData: any = decodeToken();

    const p = [
        {
            title: 'Fridges',
            url: '/',
            active: false,
            icon: iconSnowFlake,
            access: tokenData.access.cells,
            access_level: 0,
        },
        {
            title: 'Notifications',
            url: '/notifications',
            active: false,
            icon: iconTriangle,
            warning: 0,
            access: tokenData.access.notifications,
            access_level: 0,
        },
        {
            title: 'Tasks',
            url: '/tasks',
            active: false,
            icon: iconCheckList,
            access: tokenData.access.tasks,
            access_level: 3,
        },
        {
            title: 'Settings',
            url: '/settings',
            active: false,
            icon: iconWrench,
            access: tokenData.access.cells,
            access_level: 0,
        },
    ];
    const [pages, setPages] = useState(p);

    useEffect(() => {
        const updateNotificationWarning = (n: any) => {
            const p2 = pages.slice();
            const notificationItem = p2.find((item) => item.title === 'Notifications');
            if (notificationItem) {
                notificationItem.warning = n;
            }
            setPages(p2);
        };
        async function fetchData() {
            try {
                const response = await axios.get(`${BASE_URL}/user/info`, {
                    headers: authHeader(),
                });

                const { results } = response.data;
                updateNotificationWarning(results.notifications);
                checkOrganisationActive(results.organisation.active);
                setTimeout(function () {
                    fetchData();
                }, 20000);
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        const currentURL = window.location.pathname;
        pages.map((page) => {
            if (currentURL === page.url) {
                setActiveButton(page.title);
            }
        });
    }, []);

    const handleButtonClick = (componentName: string) => {
        setActiveButton(componentName);
        setActiveComponent(componentName);
    };

    return (
        <div className={classNames(styles.box, className)} id="menu-box">
            <div className={styles.opener}></div>
            <div className={styles.menu}>
                <div className={styles['menu-bgTop']}></div>
                <div className={styles['menu-bgBottom']}></div>
                <div className={classNames(styles['grid-container'], 'grid-container')}>
                    {pages.map(
                        (page: any, index: any) =>
                            page.access >= 3 && (
                                <Link
                                    key={index} // Adding a unique key prop to the Link component
                                    to={page.url}
                                    className={classNames(styles['menu-item'], {
                                        [styles['-active']]: activeButton === page.title,
                                    })}
                                    onClick={() => handleButtonClick(page.title)}
                                >
                                    <span className={styles.label}>{tl(page.title)}</span>
                                    <div className={styles['icon']}>
                                        <div className={styles['icon-inner']}>
                                            <ReactSVG src={page.icon} />
                                        </div>
                                        {page.warning && page.warning > 0 && (
                                            <div className={styles['warning']}>{page.warning}</div>
                                        )}
                                    </div>
                                </Link>
                            ),
                    )}
                </div>
            </div>
        </div>
    );
};
