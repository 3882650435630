import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
//import { registerDevice } from 'ttn-connector';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

gsap.registerPlugin(CustomEase);

export interface Location {
    id: number;
    name: string;
}
export interface Organisation {
    id: number;
    name: string;
}
export interface ModalProps {
    organisation?: any;
    callBack: () => void;
}

export const ModalAddSensor = ({ organisation, callBack }: ModalProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();

    const [scannedData, setScannedData] = useState<string>('');
    const [qrCodeScannerActive, setQrCodeScannerActive] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(true);
    const [locations, setLocations] = useState<Location[]>([]);
    const [locationId, setLocationId] = useState();

    const [listAllOrganisations, setListAllOrganisations] = useState(false);
    const [organisationId, setOrganisationId] = useState(0);
    const [organisations, setOrganisations] = useState<Organisation[]>([]);

    const [cellName, setName] = useState('');
    const [temp, setTemp] = useState('0');
    const [tempMax, setTempMax] = useState('8');
    const [tempMin, setTempMin] = useState('0');

    useEffect(() => {
        if (tokenData.access.organisations === 3) {
            setListAllOrganisations(true);
            if (organisation) {
                setOrganisationId(organisation);
            } else {
                setOrganisationId(tokenData.organisation_id);
            }
        } else {
            setOrganisationId(tokenData.organisation_id);
        }
    }, []);

    useEffect(() => {
        if (organisationId !== 0) {
            getLocations();
        }
    }, [organisationId]);

    useEffect(() => {
        if (listAllOrganisations) {
            getOrganisations();
        } else {
            console.log('nee');
        }
    }, [listAllOrganisations]);

    const getLocations = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/organisation/` + organisationId + `/locations`,
                {
                    headers: authHeader(),
                },
            );

            setLocations(response.data.results);
            handleLocationChange(response.data.results[0].id);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };

    const getOrganisations = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisations/`, {
                headers: authHeader(),
            });
            //console.log(response.data.results);
            setOrganisations(response.data.results);
            //handleLocationChange(response.data.results[0].id);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };

    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const buttonActionSubmit = async () => {
        //alert(scannedData);
        if (scannedData == '') {
            alert('Nog geen koeling gescanned of ingevuld');
        } else {
            //alert(scannedData);
            await sendData();
            //closeModal(callBack);
        }
    };

    const sendData = async () => {
        var scannedData2 = 'A8404163B1856E9F';
        const updatedData = {
            organisationId: organisationId,
            name: cellName,
            location: locationId,
            defaultTemperature: temp,
            deviationMax: tempMax,
            deviationMin: tempMin,
            notificationActive: 1,
            scannedData: scannedData,
        };
        console.log(updatedData);
        try {
            const response = await axios.post(`${BASE_URL}/cell`, updatedData, {
                headers: authHeader(),
            });
            //console.log(response);
            closeModal(callBack);
        } catch (error: any) {
            var r = error.response.data.results;
            if (r == 'Invalid') {
                alert("Device UID '" + scannedData + "' is geen geldig ID");
            } else if (r == 'Exists') {
                alert("Device UID: '" + scannedData + "' bestaat al");
            } else if (r == 'Server') {
                alert('Server timeout, probeer het opnieuw');
            }
            console.log(error);
        }
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };
    const handleLocationChange = (e: any) => {
        var i: any = parseInt(e);
        setLocationId(i);
    };
    const handleOrganisationChange = (e: any) => {
        var i: any = parseInt(e);
        setOrganisationId(i);
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };
    const handleScannedDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setScannedData(value);
    };

    const handleTempChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTemp(value);
    };
    const handleTempMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempMax(value);
    };
    const handleTempMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTempMin(value);
    };
    const handleNotificationsEnabledChange = () => {
        var n = notificationsEnabled;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setNotificationsEnabled(n);
    };

    useEffect(() => {
        let scanner: Html5QrcodeScanner;

        const startScanner = async () => {
            setQrCodeScannerActive(true);
            try {
                scanner = new Html5QrcodeScanner(
                    'reader',
                    {
                        fps: 24,
                        qrbox: { width: 250, height: 300 },
                    },
                    /* verbose= */ false,
                );

                scanner.render(
                    (decodedText: string, decodedResult: any) => {
                        setScannedData(decodedText);
                        scanner.clear();
                    },
                    (error: any) => {
                        // console.warn('Code scan error:', error);
                    },
                );
            } catch (err) {
                //console.error('Failed to start scanner:', err);
            }
        };
        if (!qrCodeScannerActive) {
            startScanner();
        }

        return () => {
            if (scanner) {
                //console.log('joaaa');
                scanner.clear();
            }
        };
    }, []);
    useEffect(() => {
        openModal();
    });

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Add sensor')}</h3>
                            <h5>{tl('Scan QR code')}</h5>
                        </div>
                        <div className={modalStyle['modal-qr-reader']}>
                            <div className={modalStyle['b1']}></div>
                            <div className={modalStyle['b2']}></div>
                            <div className={modalStyle['b3']}></div>
                            <div className={modalStyle['b4']}></div>
                            <div id="reader" className={modalStyle['modal-camera']}></div>
                            {scannedData && (
                                <p>
                                    {tl('Sensor')}: {scannedData}
                                </p>
                            )}
                        </div>

                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Device') + ' UID'}:</label>
                                <input
                                    type="text"
                                    value={scannedData}
                                    onChange={handleScannedDataChange}
                                />
                            </div>
                            {listAllOrganisations && (
                                <div className={modalStyle['form-group']}>
                                    <label htmlFor="">{tl('Organisation')}:</label>
                                    <select
                                        name="organisation"
                                        onChange={(e) => handleOrganisationChange(e.target.value)}
                                        value={organisationId}
                                    >
                                        {organisations.map((item: Organisation) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}

                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Location')}:</label>
                                <select
                                    name="location"
                                    onChange={(e) => handleLocationChange(e.target.value)}
                                    value={locationId}
                                >
                                    {locations.map((location: Location) => (
                                        <option key={location.id} value={location.id}>
                                            {location.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Name')}:</label>
                                <input type="text" value={cellName} onChange={handleNameChange} />
                            </div>
                            {/*<div className={modalStyle['form-group']}>
                                <label htmlFor="">Standaard temperatuur:</label>
                                <input type="text" value={temp} onChange={handleTempChange} />
                                    </div>*/}
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Max temperature')}:</label>
                                <input type="text" value={tempMax} onChange={handleTempMaxChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Min temperature')}:</label>
                                <input type="text" value={tempMin} onChange={handleTempMinChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    {tl('Notifications')}
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            notificationsEnabled === true
                                                ? modalStyle['-checked']
                                                : ''
                                        }`}
                                        onClick={handleNotificationsEnabledChange}
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text={tl('Save')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
