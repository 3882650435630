import { ReactSVG } from 'react-svg';
import iconFlagGB from '../../assets/icons/flags/gb.svg';
import iconFlagNL from '../../assets/icons/flags/nl.svg';
import styles from './language-switch.module.scss';
export interface InputProps {
    style?: string;
}

export const LanguageSwitch = ({ style }: InputProps) => {
    const lang = (lang: any) => {
        localStorage.setItem('lang', lang);
        window.location.reload();
    };
    var style2 = styles['language-switch'];
    if (style == 'relative') {
        var style2 = styles['language-switch2'];
    }

    return (
        <div className={style2}>
            <div className={styles['settings-lang']} onClick={(e) => lang('nl')}>
                <ReactSVG src={iconFlagNL} />
            </div>
            <div className={styles['settings-lang']} onClick={(e) => lang('en')}>
                <ReactSVG src={iconFlagGB} />
            </div>
        </div>
    );
};
