import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import settings from '../../dashboard/settings';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface ModalProps {
    callBack: () => void;
}

export const ModalAddOrganisation = ({ callBack }: ModalProps) => {
    let navigate: NavigateFunction = useNavigate();
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [postal, setPostal] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [timezone, setTimezone] = useState('');

    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const buttonActionSubmit = async () => {
        try {
            const data = {
                name: name,
                phone: phone,
                phone2: phone2,
                street: street,
                postal: postal,
                city: city,
                country: country,
                timezone: timezone,
            };
            const response = await axios.post(`${BASE_URL}/organisation`, data, {
                headers: authHeader(),
            });
            console.log(response);
            setTimeout(function () {
                navigate(settings.BASE_URL + '/organisation/' + response.data.results);
            }, 600);
            closeModal(callBack);
        } catch (error) {
            console.log(error);
        }
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };
    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPhone(value);
    };
    const handlePhone2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPhone2(value);
    };
    const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setStreet(value);
    };
    const handlePostalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPostal(value);
    };
    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCity(value);
    };
    const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCountry(value);
    };
    const handleTimezoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTimezone(value);
    };

    useEffect(() => {}, []);
    useEffect(() => {
        openModal();
    });
    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Add organisation')}</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Name')}:</label>
                                <input type="text" value={name} onChange={handleNameChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Primairy phone number')}:</label>
                                <input type="text" value={phone} onChange={handlePhoneChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Secondary phone number')}:</label>
                                <input type="text" value={phone2} onChange={handlePhone2Change} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Street')}:</label>
                                <input type="text" value={street} onChange={handleStreetChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Postal')}:</label>
                                <input type="text" value={postal} onChange={handlePostalChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('City')}:</label>
                                <input type="text" value={city} onChange={handleCityChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Country')}:</label>
                                <input type="text" value={country} onChange={handleCountryChange} />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Timezone')}:</label>
                                <input
                                    type="text"
                                    value={timezone}
                                    onChange={handleTimezoneChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text={tl('Cancel')}
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text={tl('Save')}
                        />
                        {/*<ModalButton
                            callBack={buttonActionDelete}
                            style="red"
                            icon="remove"
                            text="Verwijderen"
    />*/}
                    </div>
                </div>
            </div>
        </>
    );
};
