import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

gsap.registerPlugin(CustomEase);

export interface ModalProps {
    organisation?: any;
    callBack: () => void;
}

export const ModalAddLocation = ({ organisation, callBack }: ModalProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);

    const [scannedData, setScannedData] = useState<string | null>(null);
    const [qrCodeScannerActive, setQrCodeScannerActive] = useState(false);
    const [organisationId, setOrganisationId] = useState(organisation);
    const [name, setName] = useState('');

    const buttonActionClose = () => {
        closeModal(callBack);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };
    const buttonActionSubmit = async () => {
        try {
            const data = {
                organisationId: organisationId,
                name: name,
            };
            const response = await axios.post(`${BASE_URL}/location`, data, {
                headers: authHeader(),
            });

            closeModal(callBack);
        } catch (error) {}
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };
    useEffect(() => {
        if (organisationId === undefined) {
            setOrganisationId(tokenData.organisation_id);
        }
    }, []);
    useEffect(() => {
        openModal();
    });
    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Add location')}</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['form-group']}>
                            <label htmlFor="">{tl('Name')}:</label>
                            <input type="text" value={name} onChange={handleNameChange} />
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text={tl('Save')}
                        />
                        {/*<ModalButton
                            callBack={buttonActionDelete}
                            style="red"
                            icon="remove"
                            text="Verwijderen"
    />*/}
                    </div>
                </div>
            </div>
        </>
    );
};
