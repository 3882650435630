import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconPlus from '../../assets/icons/plus.svg';
import { ModalAddLocation } from '../../components/modals/add-location';
import { ModalEditLocation } from '../../components/modals/edit-location';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
export interface SettingsProps {
    className?: string;
}

export const DashboardPageOrganisationLocations = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [currentLocation, setCurrentLocation] = useState<any>(null);
    const [showModalAddLocation, setShowModalAddLocation] = useState(false);
    const { id } = useParams<{ id: string }>();
    const [showModalEditLocation, setShowModalEditLocation] = useState(false);
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchCells();
    }, []);

    const fetchCells = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisation/` + id + `/locations`, {
                headers: authHeader(),
            });
            var data2 = response.data.results;
            console.log(data2);
            setData(data2);
        } catch (error) {
            console.error('Failed to fetch organisations:', error);
        }
    };

    const openEditLocationModal = (location: any) => {
        setCurrentLocation(location);
        setShowModalEditLocation(true);
    };
    const callbackEditLocation = async () => {
        //reload data
        fetchCells();
        setShowModalEditLocation(false);
    };
    const callbackAddLocation = async () => {
        fetchCells();
        setShowModalAddLocation(false);
    };

    return (
        <>
            <section className={styles['section-overview-content']}>
                <div className={styles['grid']}>
                    <div className={styles['bar']}>
                        <div
                            className={styles['button']}
                            onClick={() => setShowModalAddLocation(true)}
                        >
                            <ReactSVG src={iconPlus} />
                            {tl('')}
                        </div>
                    </div>
                    <div className={`${styles['list']} ${styles['-list-locations']}`}>
                        <div className={styles['list-head']}>
                            <div className={`${styles['column']} ${styles['-locations-1']}`}>
                                {tl('Name')}:
                            </div>
                        </div>
                        {data.map((item: any) => (
                            <li className={styles['item']}>
                                <a href="#" onClick={(e) => openEditLocationModal(item)}>
                                    <div
                                        className={`${styles['column']} ${styles['-locations-1']}`}
                                    >
                                        <div className={styles['title']}>{item.name}</div>
                                        <div className={styles['sub']}>{item.name}</div>
                                    </div>
                                </a>
                            </li>
                        ))}
                    </div>
                </div>
            </section>
            {showModalEditLocation && (
                <ModalEditLocation
                    location={currentLocation}
                    callBack={() => callbackEditLocation()}
                    callBack2={() => callbackEditLocation()}
                />
            )}
            {showModalAddLocation && (
                <ModalAddLocation organisation={id} callBack={() => callbackAddLocation()} />
            )}
        </>
    );
};
