import axios from 'axios';
import {
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import { gsap, Power3 } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { CardNotification } from '../../components/cards/card-notification';
import { HeaderTitle } from '../../components/header-title/header-title';
import {
    BatteryBlock,
    InfoBlocks,
    MoistureBlock,
    TemperatureBlock,
    TimeBlock,
} from '../../components/info-blocks/info-blocks';
import { ModalEditCell } from '../../components/modals/edit-cell';
import { ModalNotification } from '../../components/modals/notification';
import { NotificationsOverview } from '../../components/notifications-overview/notifications-overview';
import { PopupRemoveCell } from '../../components/popup/remove-cell';
import { TemperatureLineChart } from '../../components/temperature-Line-Chart/temperatureLineChart';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from './cell.module.scss';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
);

interface Cell {
    id: number;
    name: string;
    active: number;
    notifications_enabled: number;
    notification_active: number;
    last_reading_timestamp: number;
    last_reading_temperature: number;
    notification_default_temperature: number;
    notification_deviation_min: number;
    notification_deviation_max: number;
}
export interface MeasurepointProps {
    className?: string;
    slug?: string;
}

export const Cell = ({ className }: MeasurepointProps) => {
    let navigate: NavigateFunction = useNavigate();
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();

    const is: any = [
        { name: '1d', n: 1 },
        { name: '7d', n: 7 },
        { name: '30d', n: 30 },
        { name: '3m', n: 91 },
        { name: '12m', n: 356 },
    ];
    const [intervals, setIntervals] = useState(is);
    const [intervalPeriod, setIntervalPeriod] = useState('3hour');
    var currentDate = new Date();
    var lastMonth = new Date();

    lastMonth.setDate(lastMonth.getDate() - 7);
    const [historyData, setHistoryData] = useState<any>(null);
    const { id } = useParams<{ id: string }>();
    const [cellData, setCellData] = useState<any>(null);
    const [measurepointData, setMeasurepointData] = useState<any>(null);
    const [graphData, setGraphData] = useState<any>(null)!;
    const [graphTab, setGraphTab] = useState<any>(7);
    const [notificationData, setNotificationData] = useState<any>(null)!;
    const [graphLoading, setGraphLoading] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [startDate, setStartDate] = useState<any>(lastMonth);
    const [endDate, setEndDate] = useState<any>(currentDate);
    const [showModalEditCell, setShowModalEditCell] = useState(false);
    const [showPopupRemoveCell, setShowPopupRemoveCell] = useState(false);
    const [currentNotification, setCurrentNotification] = useState<any>();
    const [showModalNotification, setShowModalNotification] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);

    const openModal = (cell: Cell) => {
        setShowEditForm(true);

        setTimeout(function () {
            var notificationModal = document.getElementById('notification-modal');
            gsap.to(notificationModal, { y: 0, ease: Power3.easeOut });
            var modalBackground = document.getElementById('modal-background');
            gsap.to(modalBackground, { opacity: 1, ease: Power3.easeOut, duration: 1 });
            var scrollContainer = document.getElementById('scrollContainer');
            gsap.set(scrollContainer, { overflow: 'hidden' });
        }, 100);
    };

    useEffect(() => {
        const startDate2 = Math.round(new Date(startDate).getTime() / 1000);
        const endDate2 = Math.round(new Date(endDate).getTime() / 1000 + 24 * 60 * 60 - 3);
        fetchHistoryData(startDate2, endDate2);
    }, [startDate, endDate, intervalPeriod]);

    function updateGraph(period: number) {
        const currentDate = new Date();
        const end = Math.round(currentDate.getTime() / 1000);
        const start = end - period * 24 * 60 * 60;
        console.log(start, end);
        fetchHistoryData(start, end);
        setGraphTab(period);
    }

    const fetchBasicData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/cell/${id}`, {
                headers: authHeader(),
            });
            const jsonData = response.data.results;

            if (jsonData.organisation_id != tokenData.organisation_id) {
                navigate('/');
            } else {
                setCellData(jsonData);
                console.log(jsonData);
            }
        } catch (error) {
            console.error('Error fetching measurepoint data:', error);
        }
    };

    useEffect(() => {
        fetchBasicData();

        fetchNotificationData();
    }, [id]);

    const fetchNotificationData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/cell/${id}/notifications`, {
                headers: authHeader(),
            });
            const jsonData = response.data.results;
            setNotificationData(jsonData);
        } catch (error) {
            console.error('Error fetching measurepoint data:', error);
        }
    };

    const fetchHistoryData = async (start: number, end: number) => {
        setGraphLoading(true);
        console.log(start, end);

        try {
            const response = await axios.get(`${BASE_URL}/cell/${id}/history/v2`, {
                headers: authHeader(),
                params: {
                    period: intervalPeriod,
                    start: start,
                    end: end,
                },
            });

            const jsonData = response.data.results;
            setGraphData(jsonData);
            setGraphLoading(false);
        } catch (error) {
            console.error('Error fetching measurepoint data:', error);
        }
    };

    const callbackEditCell = async () => {
        fetchBasicData();
        setShowModalEditCell(false);
    };
    const callbackEditCell2 = async () => {
        setShowModalEditCell(false);
        setShowPopupRemoveCell(true);
    };

    const callbackRemoveCell = async () => {
        setShowPopupRemoveCell(false);
        navigate('/');
    };
    const openNotificationModal = (notification: any) => {
        setCurrentNotification(notification);
        setShowModalNotification(true);
    };

    const callbackNotification = async () => {
        await Promise.all([fetchNotificationData()]);
        setTimeout(function () {
            setShowModalNotification(false);
        }, 600);
    };
    return (
        <>
            {cellData && (
                <>
                    <HeaderTitle title={cellData.name} description={cellData.id_source} />
                    <div className={styles['action-box']}>
                        <div
                            className={styles['action-icon']}
                            onClick={() => setShowModalEditCell(true)}
                        >
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 10 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.84778 3.44845L7.35363 0.939661C7.25215 0.845874 7.13505 0.798981 7.00234 0.798981C6.86963 0.798981 6.75254 0.845874 6.65105 0.939661L0.152225 7.44611C0.105386 7.50082 0.0683061 7.55357 0.0409836 7.60437C0.0136611 7.65517 0 7.71965 0 7.79781V10.2949C0 10.4512 0.046838 10.5743 0.140515 10.6642C0.234192 10.754 0.355191 10.799 0.503513 10.799H2.99766C3.07572 10.799 3.14208 10.7873 3.19672 10.7638C3.25137 10.7404 3.30211 10.7013 3.34895 10.6466L9.84778 4.15185C9.94926 4.05025 10 3.93302 10 3.80015C10 3.66729 9.94926 3.55006 9.84778 3.44845ZM2.79859 9.8025H1.00703V7.9971L7.00234 1.99476L8.79391 3.80015L2.79859 9.8025Z"
                                    fill="#055192"
                                />
                            </svg>
                            {tl('Edit')}
                        </div>
                    </div>

                    <InfoBlocks>
                        <TemperatureBlock temperature={cellData.last_reading_temperature} />
                        <MoistureBlock moisture={cellData.last_reading_moisture} />
                        <TimeBlock lastReading={cellData.last_reading_timestamp} />
                        <BatteryBlock lastReading={cellData.last_reading_battery} />
                    </InfoBlocks>
                </>
            )}
            {notificationData && (
                <NotificationsOverview title={tl('Active notifications')}>
                    {notificationData.map((notification: any) => (
                        <CardNotification
                            notification={notification}
                            callBack={() => openNotificationModal(notification)}
                        />
                    ))}
                </NotificationsOverview>
            )}

            {cellData && (
                <section>
                    <div className="grid-container">
                        <div className={styles['chart-container']}>
                            <h4>{tl('Overview')}</h4>
                            <div className={styles['chart-dates']}>
                                <div className={styles['date-box']}>
                                    <span className={styles['date-text']}>{tl('Begin date')}:</span>
                                    <DatePicker
                                        selected={startDate}
                                        dateFormat="dd-MM-yyyy"
                                        onChange={(date) => setStartDate(date)}
                                    />
                                </div>
                                <div className={styles['date-box']}>
                                    <span className={styles['date-text']}>{tl('End date')}:</span>
                                    <DatePicker
                                        dateFormat="dd-MM-yyyy"
                                        selected={endDate}
                                        onChange={(date) => setEndDate(date)}
                                    />
                                </div>
                                <div className={styles['date-box']}>
                                    <span className={styles['date-text']}>{tl('Interval')}:</span>
                                    <select
                                        name="location"
                                        onChange={(e) => setIntervalPeriod(e.target.value)}
                                        value={intervalPeriod}
                                    >
                                        <option value="1hour">1 {tl('Hour')}</option>
                                        <option value="3hour">3 {tl('Hour')}</option>
                                        <option value="6hour">6 {tl('Hour')}</option>
                                        <option value="1day">1 {tl('Day')}</option>
                                        <option value="1week">1 {tl('Week')}</option>
                                    </select>
                                </div>
                            </div>

                            <div className={styles['chart-container-inner']}>
                                <div className={styles['chart-tabs']}>
                                    <ul>
                                        {intervals.map((interval: any) => (
                                            <li
                                                className={
                                                    graphTab === interval.n ? styles['active'] : ''
                                                }
                                                onClick={(e) => updateGraph(interval.n)}
                                            >
                                                {interval.name}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {graphData && cellData && (
                                    <>
                                        <TemperatureLineChart
                                            reversedGroupedData={graphData}
                                            minLine={cellData.notification_deviation_min}
                                            maxLine={cellData.notification_deviation_max}
                                        />
                                    </>
                                )}
                                {graphLoading && cellData && (
                                    <>
                                        <div
                                            id={styles['graph-loader']}
                                            className={graphLoading ? styles['active'] : ''}
                                        >
                                            <svg
                                                version="1.1"
                                                id="L9"
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 100 100"
                                                enableBackground="new 0 0 0 0"
                                            >
                                                <path
                                                    fill="blue"
                                                    d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                                                >
                                                    <animateTransform
                                                        attributeName="transform"
                                                        attributeType="XML"
                                                        type="rotate"
                                                        dur="1s"
                                                        from="0 50 50"
                                                        to="360 50 50"
                                                        repeatCount="indefinite"
                                                    />
                                                </path>
                                            </svg>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            )}

            {showModalEditCell && cellData && (
                <ModalEditCell
                    cell={cellData}
                    callBack={() => callbackEditCell()}
                    callBack2={() => callbackEditCell2()}
                />
            )}
            {showPopupRemoveCell && cellData && (
                <PopupRemoveCell cell={cellData} callBack={() => callbackRemoveCell()} />
            )}
            {showModalNotification && cellData && (
                <ModalNotification
                    notification={currentNotification}
                    callBack={() => callbackNotification()}
                />
            )}
        </>
    );
};
