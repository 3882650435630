import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

gsap.registerPlugin(CustomEase);

export interface ModalNotificationProps {
    notification: any;
    callBack: () => void;
}
export interface Location {
    id: number;
    name: string;
}
export const ModalNotification = ({ notification, callBack }: ModalNotificationProps) => {
    const [active, setActive] = useState<any>(true);
    const [feedback, setFeedback] = useState<any>('');

    const handleActiveChange = () => {
        var n = active;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setActive(n);
    };
    const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setFeedback(value);
    };

    const buttonActionSubmit = async () => {
        if (active) {
            var action = 'open';
        } else {
            var action = 'close';
        }
        try {
            const response = await axios.put(
                `${BASE_URL}/notification/${notification.id}`,
                {
                    action: action,
                    feedback: feedback,
                },
                { headers: authHeader() },
            );
            if (response.status === 200) {
                closeModal(callBack);
            } else {
                console.error('Failed to complete notification:', response.data);
            }
        } catch (error) {
            console.error('Error during Axios request:', error);
        }
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };

    const getNotification = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/notification/${notification.id}`, {
                headers: authHeader(),
            });
            if (response.status === 200) {
                var result = response.data.results[0];

                setFeedback(result['feedback']);
            } else {
                console.error('Failed to complete notification:', response.data);
            }
        } catch (error) {
            console.error('Error during Axios request:', error);
        }
    };

    useEffect(() => {
        openModal();
        getNotification();
        if (notification.active == 1) {
            setActive(false);
        } else {
            setActive(true);
        }
    }, [notification]);

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Notification')}</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Comment')}</label>
                                <input
                                    type="text"
                                    value={feedback}
                                    onChange={handleFeedbackChange}
                                />
                            </div>
                            {/*
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    Actieve melding
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            active === true ? modalStyle['-checked'] : ''
                                        }`}
                                        onClick={handleActiveChange}
                                    ></div>
                                </div>
                                    </div> */}
                        </div>
                    </div>
                    {notification.active == 1 ? (
                        <>
                            <div className={modalStyle['modal-buttons']}>
                                <ModalButton
                                    callBack={buttonActionSubmit}
                                    style=""
                                    icon="check"
                                    text={tl('Deactivate')}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={modalStyle['modal-buttons']}>
                                <ModalButton
                                    callBack={buttonActionSubmit}
                                    style=""
                                    icon="rotate"
                                    text={tl('Reactivate')}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
