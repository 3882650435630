import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconPlus from '../../assets/icons/plus.svg';
import { ModalAddUser } from '../../components/modals/add-user';
import { ModalEditUser } from '../../components/modals/edit-user';
import { TimeAgoFormatted } from '../../functions/time/time';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
export interface SettingsProps {
    className?: string;
}

export const DashboardPageOrganisationUsers = ({ className }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const { id } = useParams<{ id: string }>();
    const [showModalAddUser, setShowModalAddUser] = useState(false);
    const [showModalEditUser, setShowModalEditUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(0);
    const [data, setData] = useState<any>([]);
    useEffect(() => {
        fetchCells();
    }, []);

    const fetchCells = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisation/` + id + `/users`, {
                headers: authHeader(),
            });
            var data2 = response.data.results;
            data2.forEach((user: any) => {
                user.name = user.firstname + ' ' + user.lastname;
                if (user.name === ' ') {
                    user.name = '-';
                }
            });
            setData(data2);
        } catch (error) {
            console.error('Failed to fetch organisations:', error);
        }
    };

    const openAddUserModal = () => {
        setShowModalAddUser(true);
    };
    const openEditUserModal = (id: any) => {
        setCurrentUser(id);
        setShowModalEditUser(true);
    };
    const callbackAddUser = async () => {
        //reload data
        fetchCells();
        setShowModalAddUser(false);
    };
    const callbackEditUser = async () => {
        //reload data
        fetchCells();
        setShowModalEditUser(false);
    };
    const callbackRemoveUser = async () => {
        //reload data
        fetchCells();
        setShowModalEditUser(false);
    };

    return (
        <>
            <section className={styles['section-overview-content']}>
                <div className={styles['grid']}>
                    <div className={styles['bar']}>
                        <div className={styles['button']} onClick={() => setShowModalAddUser(true)}>
                            <ReactSVG src={iconPlus} />
                            {tl('')}
                        </div>
                    </div>

                    <div className={`${styles['list']} ${styles['-list-users']}`}>
                        <div className={styles['list-head']}>
                            <div className={`${styles['column']} ${styles['-users-1']}`}>
                                {tl('Email')}:
                            </div>
                            <div className={`${styles['column']} ${styles['-users-2']}`}>
                                {tl('Last active')}:
                            </div>
                        </div>
                        {data.map((item: any) => (
                            <li className={styles['item']}>
                                <a href="#" onClick={(e) => openEditUserModal(item)}>
                                    <div className={`${styles['column']} ${styles['-users-1']}`}>
                                        <div className={styles['title']}>{item.email}</div>
                                        <div className={styles['sub']}>{item.name}</div>
                                    </div>
                                    <div className={`${styles['column']} ${styles['-users-2']}`}>
                                        {TimeAgoFormatted(item.last_active)}
                                    </div>
                                </a>
                            </li>
                        ))}
                    </div>
                </div>
            </section>
            {showModalEditUser && (
                <ModalEditUser
                    user={currentUser}
                    callBack={() => callbackEditUser()}
                    callBack2={() => callbackRemoveUser()}
                />
            )}
            {showModalAddUser && (
                <ModalAddUser organisation={id} callBack={() => callbackAddUser()} />
            )}
        </>
    );
};
