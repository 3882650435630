import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/button/button';
import { ModalEditCell } from '../../components/modals/edit-cell';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
export interface SettingsProps {
    className?: string;
}

export const DashboardPageOrganisationInfo = ({ className }: SettingsProps) => {
    const emptyForm = {
        name: '',
    };
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [currentCell, setCurrentCell] = useState<any>(null);
    const { id } = useParams<{ id: string }>();
    const [showModalEditCell, setShowModalEditCell] = useState(false);
    const [data, setData] = useState<any>([]);
    const [dataLoaded2, setDataLoaded2] = useState(false);
    const [data2, setData2] = useState<any>([]);
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');
    const [postal, setPostal] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [timezone, setTimezone] = useState('');
    const [active, setActive] = useState(false);

    useEffect(() => {
        fetchCells();
    }, []);
    const fetchCells = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisation/` + id, {
                headers: authHeader(),
            });
            var data = response.data.results[0];
            setName(data.name);
            setPhone(data.phone);
            setPhone2(data.phone2);
            setStreet(data.street);
            setPostal(data.postal);
            setCity(data.city);
            setCountry(data.country);
            setTimezone(data.timezone);
            if (data.active !== 1) {
                setActive(false);
            } else {
                setActive(true);
            }

            setDataLoaded2(true);
        } catch (error) {
            console.error('Failed to fetch organisations:', error);
        }
    };

    const buttonActionSubmit = async () => {
        if (active) {
            var active2 = 1;
        } else {
            var active2 = 0;
        }
        var updatedData = {
            name: name,
            phone: phone,
            phone2: phone2,
            street: street,
            postal: postal,
            country: country,
            city: city,
            timezone: timezone,
            active: active2,
        };

        try {
            const response = await axios.put(`${BASE_URL}/organisation/` + id, updatedData, {
                headers: authHeader(),
            });
            console.log(response);
        } catch (error) {
            console.log(error);
        }
    };

    const openEditCellModal = (cell: any) => {
        setCurrentCell(cell);
        setShowModalEditCell(true);
    };
    const callbackEditCell = async () => {
        //reload data
        fetchCells();
        setShowModalEditCell(false);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };
    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPhone(value);
    };
    const handlePhone2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPhone2(value);
    };
    const handleStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setStreet(value);
    };
    const handlePostalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPostal(value);
    };
    const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCity(value);
    };
    const handleCountryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setCountry(value);
    };
    const handleTimezoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTimezone(value);
    };
    const handleActiveChange = () => {
        var n = active;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setActive(n);
    };

    return (
        <>
            <section className={styles['section-overview-content']}>
                <div className={styles['grid']}>
                    <div className={styles['form']}>
                        <h4>{tl('General info')}:</h4>
                        <div className={`${styles['form-group']} ${styles['-full']}`}>
                            <label htmlFor="name">{tl('Name')}:</label>
                            <input type="text" value={name} onChange={handleNameChange} />
                        </div>

                        <div className={`${styles['form-group']} ${styles['-half']}`}>
                            <label htmlFor="name">{tl('Primairy phone number')}:</label>
                            <input type="text" value={phone} onChange={handlePhoneChange} />
                        </div>
                        <div className={`${styles['form-group']} ${styles['-half']}`}>
                            <label htmlFor="name">{tl('Secondary phone number')}:</label>
                            <input type="text" value={phone2} onChange={handlePhone2Change} />
                        </div>
                        <div className={`${styles['form-group']} ${styles['-half']}`}>
                            <label htmlFor="name">{tl('City')}:</label>
                            <input type="text" value={city} onChange={handleCityChange} />
                        </div>
                        <div className={`${styles['form-group']} ${styles['-half']}`}>
                            <label htmlFor="name">{tl('Street')}:</label>
                            <input type="text" value={street} onChange={handleStreetChange} />
                        </div>
                        <div className={`${styles['form-group']} ${styles['-half']}`}>
                            <label htmlFor="name">{tl('Postal')}:</label>
                            <input type="text" value={postal} onChange={handlePostalChange} />
                        </div>

                        <div className={`${styles['form-group']} ${styles['-half']}`}>
                            <label htmlFor="name">{tl('Country')}:</label>
                            <input type="text" value={country} onChange={handleCountryChange} />
                        </div>
                        <div className={styles['form-group']} style={{ display: 'none' }}>
                            <label htmlFor="name">{tl('Timezone')}:</label>
                            <input type="text" value={timezone} onChange={handleTimezoneChange} />
                        </div>
                        <div
                            className={`${styles['form-group']} ${styles['-full']} ${styles['-checkbox']}`}
                        >
                            <label htmlFor="repeats" className={styles['inline']}>
                                {tl('Active organisation')}
                            </label>
                            <div className={styles['checkbox-container']}>
                                <div
                                    className={`${styles['checkbox']} ${
                                        active === true ? styles['-checked'] : ''
                                    }`}
                                    onClick={handleActiveChange}
                                ></div>
                            </div>
                        </div>

                        <Button callBack={buttonActionSubmit} style="" icon="" text={tl('Save')} />
                    </div>
                </div>
            </section>
            {showModalEditCell && (
                <ModalEditCell
                    cell={currentCell}
                    callBack={() => callbackEditCell()}
                    callBack2={() => callbackEditCell()}
                />
            )}
        </>
    );
};
