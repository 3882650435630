import axios from 'axios';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import iconPlus from '../../assets/icons/plus.svg';
import { ModalAddSensor } from '../../components/modals/add-sensor';
import { ModalEditCell } from '../../components/modals/edit-cell';
import { PopupRemoveCell } from '../../components/popup/remove-cell';
import { ListSort } from '../../functions/list/sort';
import { TemperatureFormatted } from '../../functions/temperature/temperature';
import { TimeAgoFormatted } from '../../functions/time/time';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';

export interface SettingsProps {
    organisation?: any;
}

export const ListCells = ({ organisation }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [currentCell, setCurrentCell] = useState<any>(null);
    const [organisationId, setOrganisationId] = useState(organisation);
    const [showModalEditSensor, setShowModalEditSensor] = useState(false);
    const [showModalAddSensor, setShowModalAddSensor] = useState(false);
    const [showPopupRemoveCell, setShowPopupRemoveCell] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState<any>([]);
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('ASC');

    const listSortSettings = {
        default: {
            orderby: 'id',
            order: 'DESC',
        },
        orderby: {
            id: 'Added',
            name: 'Name',
            id_source: 'Device UID',
            status: 'Offline',
            last_reading_temperature: 'Temp',
            last_reading_timestamp: 'Last active',
            location_name: 'Location',
            organisation_name: 'Organisation',
        },
    };

    useEffect(() => {
        fetchCells();
    }, []);

    const fetchCells = async () => {
        var url = '/cells';
        if (organisationId) {
            var url = '/organisation/' + organisationId + '/cells';
        }
        try {
            const response = await axios.get(`${BASE_URL}` + url, {
                headers: authHeader(),
            });
            var data2 = response.data.results;
            if (data2.length > 0) {
                setData(data2);
                setDataLoaded(true);
            }
        } catch (error) {
            console.error('Failed to fetch organisations:', error);
        }
    };

    useEffect(() => {
        if (dataLoaded) {
            changeOrder();
        }
    }, [orderBy, order, dataLoaded]);

    const handleOrder = (type: string) => {
        setOrder(type);
    };

    const handleOrderBy = (type: string) => {
        setOrderBy(type);
    };

    const handleHeadOrder = (type: string) => {
        if (type == orderBy) {
            if (order == 'ASC') {
                setOrder('DESC');
            } else {
                setOrder('ASC');
            }
        } else {
            setOrderBy(type);
        }
    };

    const changeOrder = () => {
        var tempData = data.slice();
        var number = true;
        if (typeof tempData[0][orderBy] === 'string') {
            number = false;
        }

        if (order == 'ASC') {
            if (number) {
                tempData.sort((a: any, b: any) => {
                    return a[orderBy] - b[orderBy];
                });
            } else {
                tempData.sort((a: any, b: any) => a[orderBy].localeCompare(b[orderBy]));
            }
        } else {
            if (number) {
                tempData.sort((a: any, b: any) => {
                    return b[orderBy] - a[orderBy];
                });
            } else {
                tempData.sort((a: any, b: any) => b[orderBy].localeCompare(a[orderBy]));
            }
        }

        setData(tempData);
    };

    const openEditCellModal = (cell: any) => {
        setCurrentCell(cell);
        setShowModalEditSensor(true);
    };
    const callbackEditCell = async () => {
        //reload data
        fetchCells();
        setShowModalEditSensor(false);
    };
    const callbackEditCell2 = async () => {
        setShowModalEditSensor(false);
        setShowPopupRemoveCell(true);
    };

    const callbackRemoveCell = async () => {
        setShowPopupRemoveCell(false);
        fetchCells();
    };

    const callbackAddCell = async () => {
        setShowModalAddSensor(false);
        fetchCells();
    };

    return (
        <>
            <ListSort settings={listSortSettings} />
            <section className={styles['section-overview-content']}>
                <div className={styles['grid']}>
                    <div className={styles['bar']}>
                        <div
                            className={`${styles['button']} ${styles['-']}`}
                            onClick={() => setShowModalAddSensor(true)}
                        >
                            <ReactSVG src={iconPlus} />
                            {tl('')}
                        </div>
                        <div className={styles['filter']}>
                            <div className={styles['filter-box']}>
                                <span>{tl('Sort by')}:</span>
                                <select
                                    value={orderBy}
                                    name="sort-by"
                                    id="sort-by"
                                    onChange={(e) => handleOrderBy(e.target.value)}
                                >
                                    <option value="id">{tl('Added')}</option>
                                    <option value="name">{tl('Name')}</option>
                                    <option value="status">{tl('Offline')}</option>
                                    <option value="last_reading_temperature">{tl('Temp')}</option>
                                    <option value="last_reading_timestamp">
                                        {tl('Last active')}
                                    </option>
                                    <option value="location_name">{tl('Location')}</option>
                                    <option value="organisation_name">{tl('Organisation')}</option>
                                </select>
                                <select
                                    name="sort-by"
                                    value={order}
                                    id="sort-by"
                                    onChange={(e) => handleOrder(e.target.value)}
                                >
                                    <option value="DESC">{tl('Descending')}</option>
                                    <option value="ASC">{tl('Ascending')}</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={`${styles['list']} ${styles['-list-sensors']}`}>
                        <div className={styles['list-head']}>
                            <div
                                className={`${styles['column']} ${styles['-sensors-1']}`}
                                onClick={(e) => handleHeadOrder('last_reading_temperature')}
                            >
                                {tl('Temp')}:
                            </div>
                            <div
                                className={`${styles['column']} ${styles['-sensors-2']}`}
                                onClick={(e) => handleHeadOrder('name')}
                            >
                                {tl('Name')}:
                            </div>
                            {!organisationId ? (
                                <div
                                    className={`${styles['column']} ${styles['-sensors-3']}`}
                                    onClick={(e) => handleHeadOrder('organisation_name')}
                                >
                                    {tl('Organisation')}:
                                </div>
                            ) : (
                                <div
                                    className={`${styles['column']} ${styles['-sensors-3']}`}
                                    onClick={(e) => handleHeadOrder('location_name')}
                                >
                                    {tl('Location')}:
                                </div>
                            )}

                            <div
                                className={`${styles['column']} ${styles['-sensors-4']}`}
                                onClick={(e) => handleHeadOrder('last_reading_timestamp')}
                            >
                                {tl('Last reading')}:
                            </div>
                            <div
                                className={`${styles['column']} ${styles['-sensors-5']}`}
                                onClick={(e) => handleHeadOrder('status')}
                            >
                                {tl('Status')}:
                            </div>
                        </div>
                        {data.map((item: any) => (
                            <li className={styles['item']}>
                                <a href="#" onClick={(e) => openEditCellModal(item)}>
                                    <div
                                        className={`${styles['column']} ${styles['-sensors-1']} ${styles['-temp-box']}`}
                                    >
                                        <div className={styles['sub']}>
                                            {TemperatureFormatted(item.last_reading_temperature, 1)}
                                        </div>
                                    </div>
                                    <div className={`${styles['column']} ${styles['-sensors-2']}`}>
                                        <div className={styles['title']}>{item.name}</div>
                                        <div className={styles['sub']}>{item.id_source}</div>
                                    </div>
                                    {!organisationId ? (
                                        <div
                                            className={`${styles['column']} ${styles['-sensors-3']}`}
                                        >
                                            <div className={styles['sub']}>
                                                {item.organisation_name}
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={`${styles['column']} ${styles['-sensors-3']}`}
                                        >
                                            <div className={styles['sub']}>
                                                {item.location_name}
                                            </div>
                                        </div>
                                    )}

                                    <div className={`${styles['column']} ${styles['-sensors-4']}`}>
                                        <div className={styles['sub']}>
                                            {TimeAgoFormatted(item.last_reading_timestamp)}
                                        </div>
                                    </div>

                                    <div
                                        className={`${styles['column']} ${styles['-warning-box']} ${styles['-sensors-5']}`}
                                    >
                                        {item.status === 0 ? (
                                            <>
                                                <div className={styles['text']}>
                                                    <svg
                                                        width="24"
                                                        height="22"
                                                        viewBox="0 0 24 22"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M23.732 18.5469L13.7336 1.48438C12.9681 0.171875 11.0364 0.171875 10.2658 1.48438L0.272043 18.5469C-0.497176 19.8547 0.460902 21.5 2.00267 21.5H21.9995C23.5352 21.5 24.4961 19.8594 23.732 18.5469ZM10.8742 6.875C10.8742 6.25391 11.3781 5.75 11.9992 5.75C12.6203 5.75 13.1242 6.25625 13.1242 6.875V12.875C13.1242 13.4961 12.6203 14 12.0414 14C11.4625 14 10.8742 13.4984 10.8742 12.875V6.875ZM11.9992 18.5C11.1855 18.5 10.5255 17.84 10.5255 17.0262C10.5255 16.2125 11.185 15.5525 11.9992 15.5525C12.8134 15.5525 13.473 16.2125 13.473 17.0262C13.4711 17.8391 12.8149 18.5 11.9992 18.5Z"
                                                            fill="#ffffff"
                                                        />
                                                    </svg>
                                                    {/*item.cells_offline*/}
                                                    <span>{tl('offline')}</span>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`${styles['text']} ${styles['-green']}`}
                                                >
                                                    <svg
                                                        width="64"
                                                        height="64"
                                                        viewBox="0 0 64 64"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M59.4125 12.5875C60.2 13.3625 60.2 14.6375 59.4125 15.4125L25.4125 49.4125C24.6375 50.2 23.3625 50.2 22.5875 49.4125L4.58575 31.4125C3.80475 30.6375 3.80475 29.3625 4.58575 28.5875C5.36625 27.8 6.6325 27.8 7.41375 28.5875L24 45.175L56.5875 12.5875C57.3625 11.805 58.6375 11.805 59.4125 12.5875Z"
                                                            fill="#ffffff"
                                                        />
                                                    </svg>

                                                    {/*item.cells_offline*/}
                                                    <span>{tl('online')}</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </a>
                            </li>
                        ))}
                    </div>
                </div>
            </section>
            {showModalEditSensor && (
                <ModalEditCell
                    cell={currentCell}
                    callBack={() => callbackEditCell()}
                    callBack2={() => callbackEditCell2()}
                />
            )}
            {showPopupRemoveCell && currentCell && (
                <PopupRemoveCell cell={currentCell} callBack={() => callbackRemoveCell()} />
            )}
            {showModalAddSensor && (
                <ModalAddSensor organisation={organisationId} callBack={() => callbackAddCell()} />
            )}
        </>
    );
};
