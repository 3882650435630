import { tl } from '../../services/language/language';
import styles from './notifications-overview.module.scss';
type NotificationsOverviewProps = {
    title?: string;
    children: React.ReactNode;
};

type NotificationsElementProps = {
    notification: any;
};

export const NotificationsOverview = (props: NotificationsOverviewProps) => {
    var notifications: any = props.children;
    var title: any = props.title;
    return (
        <section>
            <div className="grid-container">
                <div className={styles.content}>
                    <h4>{title}</h4>

                    {notifications.length ? (
                        <div className={styles.cleared}>{notifications}</div>
                    ) : (
                        <p className="description">{tl('No notifications')}</p>
                    )}
                </div>
            </div>
        </section>
    );
};
