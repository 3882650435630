import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ModalAddLocation } from '../../components/modals/add-location';
import { ModalAddSensor } from '../../components/modals/add-sensor';
import { ModalAddUser } from '../../components/modals/add-user';
import { ModalEditPassword } from '../../components/modals/edit-password';
import { ModalButton } from '../../components/modals/modal';
import { tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { logout } from '../../services/auth-service';
import { tl } from '../../services/language/language';
export interface SettingsProps {
    className?: string;
}

export const Settings = ({ className }: SettingsProps) => {
    const [showModalAddSensor, setShowModalAddSensor] = useState(false);
    const [showModalAddLocation, setShowModalAddLocation] = useState(false);
    const [showModalAddUser, setShowModalAddUser] = useState(false);
    const [showModalEditPassword, setShowModalEditPassword] = useState(false);
    const modalRef = useRef<HTMLDivElement>(null);
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const navigate = useNavigate();

    const [showDashboardButton, setShowDashboardButton] = useState(false);
    const [showChangePasswordButton, setShowChangePasswordButton] = useState(true);

    useEffect(() => {
        const keyToCheck = 'dashboard';
        console.log(tokenData);
        if (keyToCheck in tokenData.access) {
            if (tokenData.access.dashboard >= 3) {
                setShowDashboardButton(true);
            }
        }
    }, []);

    const handleLogout = () => {
        logout();
        window.location.reload(); // Laad app opnieuw ivm uitloggen
    };

    const toDashboard = () => {
        navigate('/dashboard');
    };
    return (
        <>
            <HeaderTitle title={tl('Settings')} description="" />
            <section>
                <div className="grid-container">
                    {showDashboardButton && (
                        <ModalButton
                            callBack={() => toDashboard()}
                            style=""
                            icon="lock"
                            text={tl('Dashboard')}
                        />
                    )}
                    {tokenData.access.locations === 3 && (
                        <ModalButton
                            callBack={() => setShowModalAddLocation(true)}
                            style=""
                            icon="location"
                            text={tl('Manage locations')}
                        />
                    )}
                    {tokenData.access.cells === 3 && (
                        <ModalButton
                            callBack={() => setShowModalAddSensor(true)}
                            style=""
                            icon="plus"
                            text={tl('Add sensor')}
                        />
                    )}
                    {tokenData.access.users === 3 && (
                        <ModalButton
                            callBack={() => setShowModalAddUser(true)}
                            style=""
                            icon="plus"
                            text={tl('Add user')}
                        />
                    )}
                    {showChangePasswordButton && (
                        <ModalButton
                            callBack={() => setShowModalEditPassword(true)}
                            style=""
                            icon="lock"
                            text={tl('Change password')}
                        />
                    )}
                    <ModalButton
                        callBack={() => handleLogout()}
                        style="red"
                        icon="lock"
                        text={tl('Logout')}
                    />
                </div>
            </section>

            {showModalAddSensor && <ModalAddSensor callBack={() => setShowModalAddSensor(false)} />}
            {showModalAddLocation && (
                <ModalAddLocation callBack={() => setShowModalAddLocation(false)} />
            )}
            {showModalAddUser && <ModalAddUser callBack={() => setShowModalAddUser(false)} />}

            {showModalEditPassword && (
                <ModalEditPassword callBack={() => setShowModalEditPassword(false)} />
            )}
        </>
    );
};
