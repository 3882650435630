import { useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconEnvelope from '../../assets/icons/envelope.svg';
import iconLock from '../../assets/icons/lock.svg';
import logo from '../../assets/logo.svg';
import { tl } from '../../services/language/language';
import styles from './forms.module.scss';

import { login } from '../../services/auth-service';
import { Button } from '../button/button';
import { LanguageSwitch } from '../language-switch/language-switch';

export interface LoginProps {
    className?: string;
}

export const Login = ({ className }: LoginProps) => {
    let navigate: NavigateFunction = useNavigate();
    var defaultFormValues = { username: '', password: '' };
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [formValues, setFormValues] = useState<any>(defaultFormValues);

    const updateFormValues = (name: any, value: any) => {
        var formValues2 = formValues;
        formValues2[name] = value;
        setFormValues(formValues2);
    };

    const submitForm = async () => {
        await login(formValues).then(
            () => {
                window.dispatchEvent(new Event('loggedIn'));
                //navigate('/');
                //window.location.reload();
            },
            (error) => {
                setErrorMessage(tl('Username and/or password are invalid'));
            },
        );
    };

    return (
        <>
            <LanguageSwitch />
            <section className={styles['section-forms']}>
                <div className={styles['logo']}>
                    <img src={logo} alt="Albird" />
                </div>

                <div className={styles['box']}>
                    <div className={styles['inner']}>
                        <h4>{tl('Login')}</h4>
                        {errorMessage && (
                            <>
                                <div className={styles['message']}>{tl(errorMessage)}</div>
                            </>
                        )}
                        <>
                            <div className={styles['form']}>
                                <div className={styles['form-row']}>
                                    <div className={styles['icon']}>
                                        <ReactSVG src={iconEnvelope} />
                                    </div>

                                    <input
                                        type="email"
                                        name="username"
                                        placeholder={tl('Email')}
                                        onChange={(e) =>
                                            updateFormValues('username', e.target.value)
                                        }
                                    />
                                </div>

                                <div className={styles['form-row']}>
                                    <div className={styles['icon']}>
                                        <ReactSVG src={iconLock} />
                                    </div>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder={tl('Password')}
                                        onChange={(e) =>
                                            updateFormValues('password', e.target.value)
                                        }
                                    />
                                </div>

                                <Button callBack={submitForm} style="" icon="" text={tl('Login')} />
                            </div>
                        </>
                    </div>
                    <div className={styles['sub-links']}>
                        <Link to="/password-forget">{tl('Forgot password?')}</Link>
                    </div>
                </div>
            </section>
        </>
    );
};
