import { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconEnvelope from '../../assets/icons/envelope.svg';
import iconLock from '../../assets/icons/lock.svg';
import logo from '../../assets/logo.svg';
import { register } from '../../services/auth-service';
import { tl } from '../../services/language/language';
import { Button } from '../button/button';
import { LanguageSwitch } from '../language-switch/language-switch';
import styles from './forms.module.scss';

export interface LoginProps {
    className?: string;
}

export const Register = ({ className }: LoginProps) => {
    let navigate: NavigateFunction = useNavigate();
    const { hash } = useParams();
    var defaultFormValues = {
        firstname: '',
        lastname: '',
        hash: hash,
        password: '',
        password2: '',
    };
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [formValues, setFormValues] = useState<any>(defaultFormValues);

    const updateFormValues = (name: any, value: any) => {
        var formValues2 = formValues;
        formValues2[name] = value;
        setFormValues(formValues2);
    };

    const submitForm = async () => {
        var password = formValues.password;
        var password2 = formValues.password2;
        var hash = formValues.hash;
        var firstname = formValues.firstname;
        var lastname = formValues.lastname;
        if (password == password2) {
            await register(hash, firstname, lastname, password).then(
                () => {
                    navigate('/');
                    window.location.reload();
                },
                (error) => {
                    setErrorMessage(tl('Error'));
                },
            );
        }
    };

    return (
        <>
            <LanguageSwitch />
            <section className={styles['section-forms']}>
                <div className={styles['logo']}>
                    <img src={logo} alt="Albird" />
                </div>

                <div className={styles['box']}>
                    <div className={styles['inner']}>
                        <h4>{tl('Register')}</h4>
                        {errorMessage && (
                            <>
                                <div className={styles['message']}>{tl(errorMessage)}</div>
                            </>
                        )}
                        <>
                            <div className={styles['form']}>
                                <div className={styles['form-row']}>
                                    <div className={styles['icon']}>
                                        <ReactSVG src={iconEnvelope} />
                                    </div>

                                    <input
                                        type="email"
                                        name="firstname"
                                        placeholder={tl('Firstname')}
                                        onChange={(e) =>
                                            updateFormValues('firstname', e.target.value)
                                        }
                                    />
                                </div>
                                <div className={styles['form-row']}>
                                    <div className={styles['icon']}>
                                        <ReactSVG src={iconEnvelope} />
                                    </div>

                                    <input
                                        type="email"
                                        name="lastname"
                                        placeholder={tl('Lastname')}
                                        onChange={(e) =>
                                            updateFormValues('lastname', e.target.value)
                                        }
                                    />
                                </div>

                                <div className={styles['form-row']}>
                                    <div className={styles['icon']}>
                                        <ReactSVG src={iconLock} />
                                    </div>
                                    <input
                                        type="password"
                                        name="password"
                                        placeholder={tl('Password')}
                                        onChange={(e) =>
                                            updateFormValues('password', e.target.value)
                                        }
                                    />
                                </div>
                                <div className={styles['form-row']}>
                                    <div className={styles['icon']}>
                                        <ReactSVG src={iconLock} />
                                    </div>
                                    <input
                                        type="password"
                                        name="password2"
                                        placeholder={tl('Password again')}
                                        onChange={(e) =>
                                            updateFormValues('password2', e.target.value)
                                        }
                                    />
                                </div>

                                <Button
                                    callBack={submitForm}
                                    style=""
                                    icon=""
                                    text={tl('Register')}
                                />
                            </div>
                        </>
                    </div>
                </div>
            </section>
        </>
    );
};
