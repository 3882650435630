import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
//import { registerDevice } from 'ttn-connector';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

gsap.registerPlugin(CustomEase);

export interface Location {
    id: number;
    name: string;
}
export interface Organisation {
    id: number;
    name: string;
}
export interface ModalProps {
    organisation?: any;
    callBack: () => void;
}

export const ModalAddUser = ({ organisation, callBack }: ModalProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    if (organisation === undefined) {
        organisation = tokenData.organisation_id;
    }
    const [email, setEmail] = useState('');
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [organisationId, setOrganisationId] = useState(organisation);

    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const [acNames, setAcNames] = useState([
        'Locations',
        'Users',
        'Tasks',
        'Fridges',
        'Notifications',
        'Organisations',
        'Dashboard',
    ]);
    const [ac, setAc] = useState([3, 3, 3, 3, 3, 0, 0]);
    const [enableAccess, setEnableAccess] = useState(false);

    const buttonActionSubmit = async () => {
        if (email == '') {
            alert('Geen email adres');
        } else {
            const updatedData = {
                organisationId: organisationId,
                email: email,
                ac: ac,
            };
            try {
                const response = await axios.post(`${BASE_URL}/user/add`, updatedData, {
                    headers: authHeader(),
                });
                closeModal(callBack);
            } catch (error) {}
        }
    };
    const buttonActionDelete = () => {
        closeModal(callBack);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
    };
    const handleOrganisationChange = (e: any) => {
        setOrganisationId(e);
    };

    const handleAccess = (i: any, e: any) => {
        console.log(i, e);
        const value = e;
        const ac2 = ac.slice();
        ac2[i] = value;
        setAc(ac2);
    };

    useEffect(() => {
        const keyToCheck = 'users';
        if (keyToCheck in tokenData.access) {
            if (tokenData.access.users >= 3) {
                setEnableAccess(true);
            }
        }
        openModal();

        const getOrganisations = async () => {
            const token = localStorage.getItem('user')!;
            const tokenData = decodeToken();
            try {
                const response = await axios.get(`${BASE_URL}/organisations`, {
                    headers: authHeader(),
                });
                console.log(response.data.results);
                setOrganisations(response.data.results);
            } catch (error) {
                console.error('Error updating cell data:', error);
            }
        };

        getOrganisations();
    }, []);

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Add user')}</h3>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            {tokenData.access.organisations >= 3 && (
                                <div className={modalStyle['form-group']}>
                                    <label htmlFor="">{tl('Organisation')}:</label>
                                    <select
                                        name="organisation"
                                        onChange={(e) => handleOrganisationChange(e.target.value)}
                                        value={organisationId}
                                    >
                                        {organisations.map((organisation: Organisation) => (
                                            <option key={organisation.id} value={organisation.id}>
                                                {organisation.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Email')}:</label>
                                <input type="text" value={email} onChange={handleEmailChange} />
                            </div>
                            {enableAccess && (
                                <>
                                    <h5>{tl('User rights')}</h5>
                                    <div className={modalStyle['user-rights']}>
                                        {ac.map((ac3: any, index) =>
                                            acNames[index] === 'Organisations' &&
                                            tokenData.access.dashboard < 3 ? (
                                                <></>
                                            ) : acNames[index] === 'Dashboard' &&
                                              tokenData.access.dashboard < 3 ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className={modalStyle['form-group']}
                                                    key={index}
                                                >
                                                    <label htmlFor="">{tl(acNames[index])}:</label>
                                                    <select
                                                        value={ac3}
                                                        onChange={(e) =>
                                                            handleAccess(
                                                                index,
                                                                parseInt(e.target.value, 10),
                                                            )
                                                        }
                                                        required
                                                        defaultValue={ac3}
                                                    >
                                                        <option value="0">Geen</option>
                                                        <option value="1">Bekijken</option>
                                                        <option value="2">Bewerken</option>
                                                        <option value="3">Alles</option>
                                                    </select>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {/*<div className={modalStyle['form-group']}>
                                <label htmlFor="">Standaard temperatuur:</label>
                                <input type="text" value={temp} onChange={handleTempChange} />
                                    </div>*/}
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text={tl('Add')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
