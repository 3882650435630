import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BASE_URL from '../../services/apiConfig';
import { authHeader } from '../../services/auth';
import { tl } from '../../services/language/language';
import { Button } from '../button/button';
import { closePopup, openPopup } from './popup';
import popupStyle from './popup.module.scss';

gsap.registerPlugin(CustomEase);

export interface PopupProps {
    cell: any;
    callBack: () => void;
}

export const PopupRemoveCell = ({ cell, callBack }: PopupProps) => {
    const token = localStorage.getItem('user')!;
    const navigate = useNavigate();

    const buttonActionCancel = () => {
        closePopup(callBack);
    };
    const buttonActionSubmit = async () => {
        try {
            const response = await axios.delete(`${BASE_URL}/cell/${cell.id}/`, {
                headers: authHeader(),
            });

            closePopup(callBack);
        } catch (error) {
            console.log(error);
        }
        closePopup(callBack);
    };

    useEffect(() => {
        openPopup();
    });
    return (
        <>
            <div className={popupStyle['popup-overlay']}>
                <div
                    className={popupStyle['popup-background']}
                    id="popup-background"
                    onClick={() => closePopup(callBack)}
                ></div>

                <div className={popupStyle['popup-element']} id="popup-element">
                    <div className={popupStyle['popup-content']}>
                        <div className={popupStyle['popup-title']}>
                            <h3>{tl('Remove sensor')}</h3>
                            {/*<h5>{cell.name}</h5> */}
                        </div>
                        <div className={popupStyle['popup-content']}>
                            <p className={popupStyle['info']}>
                                {tl(
                                    'Are you sure you want to remove this sensor? This can NOT be undone.',
                                )}
                            </p>
                        </div>
                    </div>
                    <div className={popupStyle['popup-buttons']}>
                        <Button
                            callBack={buttonActionCancel}
                            style="light"
                            icon="close"
                            text={tl('No, cancel')}
                        />

                        <Button
                            callBack={buttonActionSubmit}
                            style="red"
                            icon="remove"
                            text={tl('Yes, remove')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
