import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

import { useNavigate } from 'react-router-dom';
gsap.registerPlugin(CustomEase);

export interface ModalEditCellProps {
    location: any;
    callBack: () => void;
    callBack2: () => void;
}
export interface Location {
    id: number;
    name: string;
}
export const ModalEditLocation = ({ location, callBack, callBack2 }: ModalEditCellProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const navigate = useNavigate();

    const [locations, setLocations] = useState<Location[]>([]);
    const [name, setName] = useState('' + location.name + '');

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
    };

    useEffect(() => {}, []);

    const buttonActionSubmit = async () => {
        try {
            const updatedData = {
                name,
            };

            await axios.put(`${BASE_URL}/location/${location.id}`, updatedData, {
                headers: authHeader(),
            });

            closeModal(callBack);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };
    const buttonActionRemove = async () => {
        try {
            var response = await axios.delete(`${BASE_URL}/location/${location.id}`, {
                headers: authHeader(),
            });

            closeModal(callBack2);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };

    useEffect(() => {
        openModal();
    });

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Edit Location')}</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Name')}:</label>
                                <input type="text" value={name} onChange={handleNameChange} />
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionRemove}
                            style="red"
                            icon="remove"
                            text={tl('Delete')}
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="check"
                            text={tl('Save')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
