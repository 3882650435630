import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface ModalAddTaskProps {
    callBack: () => void;
    taskId?: number;
}

export const ModalAddTask = ({ callBack }: ModalAddTaskProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const [repeatPeriod, setRepeatPeriod] = useState('weekly');
    const [days, setDays] = useState([
        { name: 'Maandag', value: false },
        { name: 'Dinsdag', value: false },
        { name: 'Woensdag', value: false },
        { name: 'Donderdag', value: false },
        { name: 'Vrijdag', value: false },
        { name: 'Zaterdag', value: false },
        { name: 'Zondag', value: false },
    ]);
    const [hours, setHours] = useState([
        { name: '0:00', value: false },
        { name: '06:00', value: false },
        { name: '12:00', value: false },
        { name: '18:00', value: false },
    ]);
    var d = new Date();

    let nextWeek = new Date(d.getTime() + 7 * 24 * 60 * 60 * 1000);
    const [lables, setLables] = useState([]);
    const [lablesBackend, setLablesBackend] = useState([]);
    const [subTasks, setSubTasks] = useState([]);
    const [users, setUsers] = useState([{ id: tokenData.id, name: tokenData.firstname }]);
    const [lastEmpty, setLastEmpty] = useState(true);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [taskForUser, setTaskForUser] = useState(0);
    const [taskRepeats, setTaskRepeats] = useState(true);
    const [timestamps, setTimestamps] = useState([]);

    const [endTime, setEndTime] = useState<any>(nextWeek);

    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const buttonActionSubmit = async () => {
        const sendData = async (updatedData: any) => {
            console.log(updatedData);
            try {
                const response = await axios.post(`${BASE_URL}/task`, updatedData, {
                    headers: authHeader(),
                });
                console.log(response);
                closeModal(callBack);
            } catch (error) {
                console.log(error);
            }
        };

        var lablesBackend2: any = [];
        var itemsProcessed = 0;
        lables.forEach((lable: any, index: number) => {
            if (lable.active) {
                lablesBackend2.push(lable.id);
            }
            itemsProcessed++;
            if (itemsProcessed === lables.length) {
                function getUnixTimestampsForWednesdays(numWeeks: number, dayNum: number) {
                    console.log(dayNum);
                    const millisecondsInADay = 24 * 60 * 60 * 1000;
                    const millisecondsInAWeek = 7 * millisecondsInADay;

                    const currentTimestamp = Date.now();
                    const nextWednesdaysTimestamps = [];

                    for (let i = 0; i < numWeeks; i++) {
                        for (let j = 0; j < 7; j++) {
                            const timestamp =
                                currentTimestamp + i * millisecondsInAWeek + j * millisecondsInADay;

                            const dayOfWeek = new Date(timestamp).getDay();
                            if (dayOfWeek === dayNum) {
                                var timestamp2 = Math.round(timestamp / 1000);
                                nextWednesdaysTimestamps.push(timestamp2);
                            }
                        }
                    }

                    return nextWednesdaysTimestamps;
                }

                const numWeeks = 8;
                const timestamps2: any = [];

                var n = 0;
                days.map((day: any, index: any) => {
                    if (day.value === true) {
                        var t = getUnixTimestampsForWednesdays(numWeeks, index);
                        timestamps2.push(t);
                    }
                    n++;
                    if (n == days.length) {
                        setTimestamps(timestamps2.flat());
                        const updatedData = {
                            title: title,
                            description: description,
                            subTasks: subTasks,
                            endTime: Math.round(new Date(endTime).getTime() / 1000),
                            taskForUser: taskForUser,
                            lables: lablesBackend2,
                            repeats: taskRepeats,
                            repeatsData: { timestamps: timestamps2.flat() },
                        };

                        sendData(updatedData);
                    }
                });

                //console.log(timestamps);
            }
        });
    };

    const getLables = async () => {
        try {
            const response: any = await axios.get(`${BASE_URL}/tasks/lables`, {
                headers: authHeader(),
            });

            const newArr: any = response.data.results.map((v: any) => ({ ...v, active: false }));

            setLables(newArr);
        } catch (error) {
            console.log(error);
        }
    };

    const toggleActiveDays = (name: any) => {
        const index = days.findIndex((day) => day.name === name);
        let tempDays = days.slice();
        if (tempDays[index].value) {
            tempDays[index].value = false;
        } else {
            tempDays[index].value = true;
        }

        setDays(tempDays);
    };

    const handleTaskRepeats = () => {
        var n = taskRepeats;
        if (n) {
            n = false;
        } else {
            n = true;
        }
        setTaskRepeats(n);
    };
    const handleChangeSubtask = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        var index: any = e.target.getAttribute('data-key');
        index = parseInt(index);
        var array: any = subTasks.slice();
        //console.log(value, index);
        console.log(array.length, index);
        if (array.length == index + 1) {
            if (value != '') {
                if (!lastEmpty) {
                    //setLastEmpty(true);
                }
                array.push({ name: '', value: '' });
            }
        }
        array[index].value = value;
        setSubTasks(array);
    };
    const addSubTask = () => {
        var array: any = subTasks.slice();
        array.push({ name: '', value: '' });
        setSubTasks(array);
    };
    const handleRemoveSubTask = (i: any) => {
        var array = subTasks.slice();
        array.splice(i, 1);
        setSubTasks(array);
    };
    useEffect(() => {}, [subTasks]);
    useEffect(() => {
        openModal();
        getLables();
        //setLables();
    }, []);

    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTitle(value);
    };
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDescription(value);
    };
    const handleTaskForUserChange = (e: any) => {
        const value = e.target.value;
        setTaskForUser(value);
    };

    const setLableToggleActive = (i: number) => {
        let tempLables: any = lables.slice();
        tempLables[i].active = !tempLables[i].active;

        setLables(tempLables);
    };

    useEffect(() => {
        console.log('joeee');
        setLables(lables);
    }, []);

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>Nieuwe Taak</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Title')}</label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Description')}</label>
                                <input
                                    type="text"
                                    name=""
                                    id=""
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </div>

                            <div
                                className={`${modalStyle['form-group']} ${modalStyle['-half']} ${modalStyle['-datetime']}`}
                                style={{ display: 'none' }}
                            >
                                <label htmlFor="name">Deadline</label>
                                <DatePicker
                                    selected={endTime}
                                    dateFormat="dd-MM-yyyy HH:mm"
                                    showTimeSelect
                                    onChange={(date) => setEndTime(date)}
                                />
                            </div>

                            <div
                                className={`${modalStyle['form-group']} ${modalStyle['-half']}`}
                                style={{ display: 'none' }}
                            >
                                <label htmlFor="name">{tl('Assign to')}</label>
                                <select
                                    value={users[0].id}
                                    onChange={(e) =>
                                        handleTaskForUserChange(parseInt(e.target.value, 10))
                                    }
                                    required
                                    defaultValue={taskForUser}
                                >
                                    <option value="0">Iedereen</option>
                                    {/*users.map((user, index) => (
                                        <option key={user.id} value={user.id}>
                                            {user.name}
                                        </option>
                                    ))*/}
                                </select>
                            </div>

                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['full-row']}>
                                    {tl('Lables')}
                                </label>

                                <div
                                    className={`${modalStyle['lables-container']} ${modalStyle['full-row']}`}
                                >
                                    <ul className={modalStyle['lables']}>
                                        {lables.map((lable: any, index) => {
                                            return (
                                                <li
                                                    className={`${modalStyle['lable']} ${
                                                        lable.active === true &&
                                                        modalStyle['-active']
                                                    }`}
                                                    onClick={() => setLableToggleActive(index)}
                                                >
                                                    {lable.name}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div
                                className={`${modalStyle['form-group']} ${modalStyle['subtasks-group']}`}
                            >
                                <label htmlFor="name">{tl('Subtaks')}</label>
                                <div className={modalStyle['subtasks']}>
                                    {subTasks.map((subTask, index) => {
                                        return (
                                            <div className={modalStyle['subtask']}>
                                                <div
                                                    className={`${modalStyle['subtask-remove']} ${modalStyle['subtask-remove-end']}`}
                                                    onClick={() => handleRemoveSubTask(index)}
                                                >
                                                    <svg
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 20 20"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.75 10C19.75 10.4125 19.4125 10.75 19 10.75H10.75V19C10.75 19.4146 10.4146 19.7505 10 19.7505C9.58544 19.7505 9.25 19.4125 9.25 19V10.75H1C0.585438 10.75 0.25 10.4148 0.25 10.0005C0.25 9.5875 0.585625 9.25 1 9.25H9.25V0.999997C9.25 0.585434 9.58544 0.250465 10 0.250465C10.4146 0.250465 10.75 0.585622 10.75 0.999997V9.25H19C19.4125 9.25 19.75 9.5875 19.75 10Z"
                                                            fill="#045EAA"
                                                        />
                                                    </svg>
                                                </div>

                                                <input
                                                    type="text"
                                                    name="name"
                                                    key={index}
                                                    data-key={index}
                                                    placeholder="Voeg een subtaak toe"
                                                    id="name"
                                                    onChange={handleChangeSubtask}
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className={modalStyle['subtask-add']} onClick={addSubTask}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M19.75 10C19.75 10.4125 19.4125 10.75 19 10.75H10.75V19C10.75 19.4146 10.4146 19.7505 10 19.7505C9.58544 19.7505 9.25 19.4125 9.25 19V10.75H1C0.585438 10.75 0.25 10.4148 0.25 10.0005C0.25 9.5875 0.585625 9.25 1 9.25H9.25V0.999997C9.25 0.585434 9.58544 0.250465 10 0.250465C10.4146 0.250465 10.75 0.585622 10.75 0.999997V9.25H19C19.4125 9.25 19.75 9.5875 19.75 10Z"
                                                fill="#045EAA"
                                            />
                                        </svg>
                                        {tl('Add subtask')}
                                    </div>
                                </div>
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="repeats" className={modalStyle['inline']}>
                                    {tl('Task repeats every')}
                                </label>
                                <div className={modalStyle['checkbox-container']}>
                                    <div
                                        className={`${modalStyle['checkbox']} ${
                                            taskRepeats === true ? modalStyle['-checked'] : ''
                                        }`}
                                        onClick={handleTaskRepeats}
                                    ></div>
                                </div>
                            </div>

                            {taskRepeats ? (
                                <>
                                    <div className={modalStyle['form-group']}>
                                        <label htmlFor="repeats" className={modalStyle['inline']}>
                                            {tl('Repeat every')}:
                                        </label>

                                        <select name="" id="" className={modalStyle['inline']}>
                                            <option value="daily">{tl('Day')}</option>
                                            <option value="weekly" selected>
                                                {tl('Week')}
                                            </option>
                                        </select>
                                    </div>
                                    <div className={modalStyle['form-group']}>
                                        <label htmlFor="name" className={modalStyle['inline']}>
                                            {tl('Repeat at')}
                                        </label>
                                        <div className={modalStyle['days']}>
                                            {days.map((day, index) => {
                                                return (
                                                    <div
                                                        className={`${modalStyle['day']} ${
                                                            day.value === true
                                                                ? modalStyle['-active']
                                                                : ''
                                                        }`}
                                                        onClick={() => toggleActiveDays(day.name)}
                                                        key={index}
                                                    >
                                                        {day.name[0]}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={`${modalStyle['form-group']} ${modalStyle['-half']} ${modalStyle['-datetime']}`}
                                    >
                                        <label htmlFor="name">Deadline</label>
                                        <DatePicker
                                            selected={endTime}
                                            dateFormat="dd-MM-yyyy HH:mm"
                                            showTimeSelect
                                            onChange={(date) => setEndTime(date)}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        {/*<ModalButton
                            callBack={buttonActionClose}
                            style="light"
                            icon="close"
                            text="Annuleren"
                                        /> */}
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="plus"
                            text={tl('Add task')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
