import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';
gsap.registerPlugin(CustomEase);

export interface ModalEditCellProps {
    user: any;
    callBack: () => void;
    callBack2: () => void;
}
export interface Location {
    id: number;
    name: string;
}

export interface Organisation {
    id: number;
    name: string;
}
export const ModalEditUser = ({ user, callBack, callBack2 }: ModalEditCellProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const [email, setEmail] = useState('');
    const [organisations, setOrganisations] = useState<Organisation[]>([]);
    const [organisationId, setOrganisationId] = useState(user.organisation_id);

    useEffect(() => {
        openModal();
        const keyToCheck = 'users';
        if (keyToCheck in tokenData.access) {
            if (tokenData.access.users >= 3) {
                setEnableAccess(true);
            }
        }
        const getUser = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/user/` + user.id, {
                    headers: authHeader(),
                });
                console.log(response.data.results);
                //setLocations(response.data.results);
            } catch (error) {
                console.error('Error updating cell data:', error);
            }
        };

        getUser();
    }, []);

    const buttonActionSubmit = async () => {};

    const buttonActionRemove = async () => {
        try {
            const response = await axios.delete(`${BASE_URL}/user/${user.id}/`, {
                headers: authHeader(),
            });
            console.log(response);
            closeModal(callBack);
        } catch (error) {
            console.log(error);
        }
    };

    const buttonActionClose = () => {
        closeModal(callBack);
    };
    const [acNames, setAcNames] = useState([
        'Locations',
        'Users',
        'Tasks',
        'Fridges',
        'Notifications',
        'Organisations',
        'Dashboard',
    ]);
    const [ac, setAc] = useState([3, 3, 3, 3, 3, 0, 0]);
    const [enableAccess, setEnableAccess] = useState(false);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setEmail(value);
    };
    const handleOrganisationChange = (e: any) => {
        setOrganisationId(e);
    };

    const handleAccess = (i: any, e: any) => {
        console.log(i, e);
        const value = e;
        const ac2 = ac.slice();
        ac2[i] = value;
        setAc(ac2);
    };

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Edit user')}</h3>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            {tokenData.access.organisations >= 3 && (
                                <div className={modalStyle['form-group']}>
                                    <label htmlFor="">{tl('Organisation')}:</label>
                                    <select
                                        name="organisation"
                                        onChange={(e) => handleOrganisationChange(e.target.value)}
                                        value={organisationId}
                                    >
                                        {organisations.map((organisation: Organisation) => (
                                            <option key={organisation.id} value={organisation.id}>
                                                {organisation.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="">{tl('Email')}:</label>
                                <input type="text" value={email} onChange={handleEmailChange} />
                            </div>
                            {enableAccess && (
                                <>
                                    <h5>{tl('User rights')}</h5>
                                    <div className={modalStyle['user-rights']}>
                                        {ac.map((ac3: any, index) =>
                                            acNames[index] === 'Organisations' &&
                                            tokenData.access.dashboard < 3 ? (
                                                <></>
                                            ) : acNames[index] === 'Dashboard' &&
                                              tokenData.access.dashboard < 3 ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className={modalStyle['form-group']}
                                                    key={index}
                                                >
                                                    <label htmlFor="">{tl(acNames[index])}:</label>
                                                    <select
                                                        value={ac3}
                                                        onChange={(e) =>
                                                            handleAccess(
                                                                index,
                                                                parseInt(e.target.value, 10),
                                                            )
                                                        }
                                                        required
                                                        defaultValue={ac3}
                                                    >
                                                        <option value="0">Geen</option>
                                                        <option value="1">Bekijken</option>
                                                        <option value="2">Bewerken</option>
                                                        <option value="3">Alles</option>
                                                    </select>
                                                </div>
                                            ),
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        {/*<div className={modalStyle['form-group']}>
                    <label htmlFor="">Standaard temperatuur:</label>
                    <input type="text" value={temp} onChange={handleTempChange} />
                        </div>*/}
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionRemove}
                            style="red"
                            icon="remove"
                            text={tl('Delete')}
                        />
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="check"
                            text={tl('Save')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
