import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import styles from './App.module.scss';
import { Menu } from './components/menu/menu';
import { Navigation } from './components/navigation/navigation';
import {
    checkV1Token,
    logout,
    tokenDecoded,
    tokenGet,
    tokenValid,
    updateToken,
} from './services/auth';

import { NavigateFunction, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Login } from './components/forms/login';
import { Passwordforget } from './components/forms/passwordforget';
import { Passwordreset } from './components/forms/passwordreset';
import { Register } from './components/forms/register';
import { ScreenBlocked } from './components/screens/blocked';
import { ScreenSplash } from './components/screens/splash';
import { Dashboard } from './dashboard/dashboard';
import { Cell } from './pages/cell/cell';
import { Cells } from './pages/cells/cells';
import { Notifications } from './pages/notifications/notifications';
import { Settings } from './pages/settings/settings';
import { Tasks } from './pages/tasks/tasks';
import { Test } from './pages/test/test';

const App = () => {
    const getDashboardMode = () => {
        var a = window.localStorage.getItem('dashboardMode');

        if (a) {
            if (a == 'true') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };
    const [activeComponent, setActiveComponent] = useState('locations');
    const [dashboardMode, setDashboardMode] = useState(false);
    const [tokenValidState, setTokenValidState] = useState(false);
    const [appLoaded, setAppLoaded] = useState(false);
    const [appDisabled, setAppDisabled] = useState(false);
    const location = useLocation();
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const startTime = new Date().getTime();

    let navigate: NavigateFunction = useNavigate();

    useEffect(() => {
        if (validToken) {
            updateToken(token);
            if (checkV1Token(token)) {
                //updateToken(token);
            }
            console.log(tokenData);
            setTokenValidState(true);
        } else {
            logout();
        }
        const endTime = new Date().getTime();
        const loadTime = endTime - startTime;
        const minLoadTime = 0;
        if (loadTime < minLoadTime) {
            setTimeout(function () {
                setAppLoaded(true);
            }, minLoadTime - loadTime);
        } else {
            setAppLoaded(true);
        }

        window.addEventListener('organisationInactive', (e) => {
            var orgI = localStorage.getItem('organisationInactive');
            if (orgI == '1') {
                setAppDisabled(true);
            } else {
                setAppDisabled(false);
            }
        });
        window.addEventListener('loggedIn', (e) => {
            //alert('loggedin');
            const token = tokenGet();
            const validToken = tokenValid(token);
            if (validToken) {
                setTokenValidState(true);
            }
        });
    }, []);

    return (
        <div className={styles.App} id="app">
            {appLoaded ? (
                <>
                    {tokenValidState ? (
                        <>
                            {location.pathname.startsWith('/dashboard') ? (
                                <Dashboard />
                            ) : (
                                <div className={styles.scrollContainer} id="scrollContainer">
                                    <Navigation />
                                    <Menu setActiveComponent={setActiveComponent} />
                                    <div className={styles.main}>
                                        <Routes>
                                            <Route path="/" element={<Cells />} />
                                            <Route path="/cell/:id" element={<Cell />} />
                                            <Route
                                                path="/notifications"
                                                element={<Notifications />}
                                            />
                                            <Route path="/tasks" element={<Tasks />} />
                                            <Route path="/settings" element={<Settings />} />
                                            <Route path="/test" element={<Test />} />
                                        </Routes>
                                    </div>
                                    {appDisabled && <ScreenBlocked />}
                                </div>
                            )}
                        </>
                    ) : location.pathname.startsWith('/activate/') ? (
                        <>
                            <div className={styles.scrollContainer} id="scrollContainer">
                                <Routes>
                                    <Route path="/activate/:hash" element={<Register />} />
                                </Routes>
                            </div>
                        </>
                    ) : location.pathname.startsWith('/password-forget') ? (
                        <>
                            <div className={styles.scrollContainer} id="scrollContainer">
                                <Routes>
                                    <Route path="/password-forget" element={<Passwordforget />} />
                                </Routes>
                            </div>
                        </>
                    ) : location.pathname.startsWith('/password-reset/') ? (
                        <>
                            <div className={styles.scrollContainer} id="scrollContainer">
                                <Routes>
                                    <Route
                                        path="/password-reset/:hash"
                                        element={<Passwordreset />}
                                    />
                                </Routes>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.scrollContainer} id="scrollContainer">
                                <Login />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <ScreenSplash />
            )}
        </div>
    );
};

export default App;
