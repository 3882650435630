export const TemperatureFormatted = (temp2: any, digits: number = 1, sign: boolean = true) => {
    if (temp2 === null) {
        return '-';
    }

    var temp: any = parseFloat(temp2);
    if (typeof temp !== 'number') {
    }

    var tempReturn = temp.toFixed(digits);

    var signReturn = '°C';
    if (!sign) {
        signReturn = '';
    }

    return tempReturn + signReturn;
};
