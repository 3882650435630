import axios from 'axios';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import { Block, BlockGraph } from '../components/block-graph';
import styles from '../dashboard.module.scss';
import settings from '../settings';
export interface SettingsProps {
    className?: string;
}
export interface BlockData {
    title: string;
    text: string;
    data: any;
    loaded: boolean;
}

export const DashboardPageOverview = ({ className }: SettingsProps) => {
    const defaultBlockData = { title: '', text: '', data: '', loaded: false };
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [block1, setBlock1] = useState<any>(defaultBlockData);
    const [block2, setBlock2] = useState<any>(defaultBlockData);
    const [basicDataLoaded, setBasicDataLoaded] = useState(false);
    const [basicData, setBasicData] = useState<any>();

    const fetchBasicData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/stats/`, {
                headers: authHeader(),
            });

            var data3 = response.data.results;
            console.log(data3);
            setBasicData(data3);
            setBasicDataLoaded(true);
        } catch (error) {}
    };

    const fetchBlock1 = async () => {
        var today = new Date();
        today.setHours(23);
        today.setMinutes(59);
        today.setSeconds(59);
        today.setMilliseconds(0);
        var end = Math.floor(today.getTime() / 1000);
        console.log(end);
        //today.setMonth(today.getMonth() - 1);
        //var start = Math.round(today.getTime() / 1000);
        var start = end - 60 * 60 * 24 * 30;
        try {
            const response = await axios.get(`${BASE_URL}/stats/readings`, {
                headers: authHeader(),
                params: {
                    start: start,
                    end: end,
                },
            });

            var data3 = response.data.results;
            var newData: any = [];
            var newLabels: any = [];
            var text = '';
            console.log(response);
            data3.map((d: any, i: any) => {
                newData.push(d.row_count);
                newLabels.push(i);
                if (i + 1 == data3.length) {
                    text = d.row_count;
                }
            });
            var newData2: any = [newLabels, newData];
            var d = { title: 'Measurements last hour', text: text, data: newData2, loaded: true };
            setBlock1(d);
        } catch (error) {}
    };
    const fetchBlock2 = async () => {
        var today = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        var end = Math.floor(today.getTime() / 1000);
        today.setMonth(today.getMonth() - 1);
        var start = Math.round(today.getTime() / 1000);
        try {
            const response = await axios.get(`${BASE_URL}/stats/notifications`, {
                headers: authHeader(),
                params: {
                    start: start,
                    end: end,
                },
            });
            var data3 = response.data.results;
            var newData: any = [];
            var newLabels: any = [];
            var text = '';
            data3.map((d: any, i: any) => {
                newData.push(d.length);
                newLabels.push(i);
                if (i + 1 == data3.length) {
                    text = d.length;
                }
            });
            var newData2: any = [newLabels, newData];
            console.log(data3);
            var d = { title: 'Notifications today', text: text, data: newData2, loaded: true };
            setBlock2(d);
        } catch (error) {}
    };

    useEffect(() => {
        fetchBasicData();
        fetchBlock1();
        fetchBlock2();
    }, []);
    return (
        <>
            <section className={styles['section-header']}>
                <div className={styles['grid']}>
                    <div className={styles['box-first']}></div>
                    <div className={styles['header-title']}>
                        <h2>{tl('Dashboard')}</h2>
                    </div>
                    <div className={styles['box-last']}></div>
                </div>
            </section>

            <section className={styles['section-header-filler']}></section>

            <section className={styles['section-blocks']}>
                <div className={styles['grid']}>
                    <ul className={styles['blocks']}>
                        {basicDataLoaded && (
                            <>
                                <Block
                                    title={tl('Total readings')}
                                    text={basicData.readings_count}
                                />
                                <Block
                                    title={tl('Sensors')}
                                    text={basicData.cells_count}
                                    link={settings.BASE_URL + '/sensors'}
                                />
                                <Block
                                    title={tl('Sensors offline')}
                                    text={basicData.cells_offline_count}
                                    link={settings.BASE_URL + '/sensors'}
                                />
                                <Block
                                    title={tl('Organisations')}
                                    text={basicData.organisations_count}
                                    link={settings.BASE_URL + '/organisations'}
                                />
                                <Block title={tl('Locations')} text={basicData.locations_count} />
                                <Block title={tl('Users')} text={basicData.users_count} />
                            </>
                        )}
                    </ul>
                </div>
                <div className={styles['grid']}>
                    <ul className={styles['blocks']}>
                        {block1.loaded && (
                            <BlockGraph
                                title={block1.title}
                                text={block1.text}
                                data={block1.data}
                            />
                        )}
                        {block2.loaded && (
                            <BlockGraph
                                title={block2.title}
                                text={block2.text}
                                data={block2.data}
                            />
                        )}
                    </ul>
                </div>
            </section>
        </>
    );
};
