import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconArrowBack from '../../assets/icons/arrow-back.svg';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from '../dashboard.module.scss';
import settings from '../settings';
import { DashboardPageOrganisationInfo } from './organisation-info';
import { DashboardPageOrganisationLocations } from './organisation-locations';
import { DashboardPageOrganisationSensors } from './organisation-sensors';
import { DashboardPageOrganisationUsers } from './organisation-users';
export interface SettingsProps {
    subPage?: string;
}

export const DashboardPageOrganisation = ({ subPage }: SettingsProps) => {
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [data, setData] = useState<any>([]);
    const [dataLoaded2, setDataLoaded2] = useState(false);
    const [data2, setData2] = useState<any>([]);
    const [name, setName] = useState<any>('');
    const [currentCell, setCurrentCell] = useState<any>(null);
    const { id } = useParams<{ id: string }>();
    const [showModalEditCell, setShowModalEditCell] = useState(false);
    const p: any = [
        { name: 'Sensors', active: true },
        { name: 'Info', active: false },

        { name: 'Users', active: false },
        { name: 'Locations', active: false },
    ];
    const [pages, setPages] = useState<any>(p);
    const [dashboardPage, setDashboardPage] = useState<string>('Sensors');

    useEffect(() => {
        const fetchOrganisation = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/organisation/` + id, {
                    headers: authHeader(),
                });
                var data = response.data.results[0];
                setData(data);
                setName(data.name);
                setDataLoaded(true);
            } catch (error) {
                console.error('Failed to fetch organisations:', error);
            }
        };

        fetchOrganisation();

        //fetchCells();
    }, [id]);

    const openEditCellModal = (cell: any) => {
        setCurrentCell(cell);
        setShowModalEditCell(true);
    };
    const callbackEditCell = async () => {
        //reload data

        setShowModalEditCell(false);
    };

    const openAddModal = () => {};

    return (
        <>
            <section className={styles['section-header']}>
                <div className={styles['grid']}>
                    <div className={styles['box-first']}>
                        <Link
                            className={`${styles['header-button']}`}
                            to={settings.BASE_URL + '/organisations'}
                        >
                            <ReactSVG src={iconArrowBack} />
                            <span>{tl('Back to organisations')}</span>
                        </Link>
                    </div>
                    <div className={styles['header-title']}>
                        <h2>
                            {/*tl('Organisation')*/} {name}
                        </h2>
                    </div>
                    <div className={styles['box-last']}></div>
                </div>
            </section>

            <section className={styles['section-header-filler']}></section>

            <section className={styles['section-tabs']}>
                <div className={styles['grid']}>
                    <ul className={styles['tabs']}>
                        {pages.map((page: any, i: any) => (
                            <li
                                onClick={() => setDashboardPage(page.name)}
                                className={`${styles['menu-item']} ${
                                    dashboardPage === page.name ? styles['-active'] : ''
                                }`}
                            >
                                <span>{tl(page.name)}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>

            <section className={styles['section-tabs-filler']}></section>

            {dashboardPage === 'Info' ? (
                <DashboardPageOrganisationInfo />
            ) : dashboardPage === 'Locations' ? (
                <DashboardPageOrganisationLocations />
            ) : dashboardPage === 'Users' ? (
                <DashboardPageOrganisationUsers />
            ) : dashboardPage === 'Sensors' ? (
                <DashboardPageOrganisationSensors />
            ) : null}

            <section className={styles['section-blocks']} style={{ display: 'none' }}>
                <div className={styles['grid']}>
                    {dataLoaded && (
                        <>
                            <ul className={styles['blocks']}>
                                <li>
                                    <div className={styles['title']}>{tl('Locations')}</div>
                                    <div className={styles['count']}>{data.location_count}</div>
                                </li>
                                <li>
                                    <div className={styles['title']}>{tl('Fridges')}</div>
                                    <div className={styles['count']}>{data.cell_count}</div>
                                </li>
                                <li>
                                    <div className={styles['title']}>{tl('Sensors')}</div>
                                    <div className={styles['count']}>{data.sensor_count}</div>
                                </li>
                                <li>
                                    <div className={styles['title']}>{tl('Users')}</div>
                                    <div className={styles['count']}>{data.user_count}</div>
                                </li>
                            </ul>
                        </>
                    )}
                </div>
            </section>
        </>
    );
};
