import axios from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import iconFlagGB from '../../assets/icons/flags/gb.svg';
import iconFlagNL from '../../assets/icons/flags/nl.svg';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from './personal-settings.module.scss';
export interface PersonalSettingsProps {
    className?: string;
    organizationName?: string;
}

export const PersonalSettings = ({ className, organizationName }: PersonalSettingsProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();

    const [successful, setSuccessful] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [showForm, setShowForm] = useState<boolean>(true);
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [organisationPhone, setOrganisationPhone] = useState<string>('');
    const [phone, setPhone] = useState('');
    const [phone2, setPhone2] = useState('');

    const lang = (lang: any) => {
        localStorage.setItem('lang', lang);
        window.location.reload();
    };

    //console.log(tokenData);
    useEffect(() => {
        async function fetchData2() {
            try {
                const token = localStorage.getItem('user')!;
                const tokenData = decodeToken();

                const response = await axios.get(`${BASE_URL}/organisation`, {
                    headers: authHeader(),
                });

                const { results } = response.data;

                //checkOrganisationActive(results.active);

                const phone = results.phone;
                if (phone) {
                    setPhone(phone);
                }
                const phone2 = results.phone2;
                if (phone2) {
                    setPhone2(phone2);
                }
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        }
        fetchData2();
    }, []);

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPhone(value);
    };
    const handlePhone2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPhone2(value);
    };

    const submitForm = async () => {
        setIsSubmitting(true);
        try {
            // Use the correct URL here
            const data = {
                phone: phone,
                phone2: phone2,
            };

            const response = await axios.put(`${BASE_URL}/organisation/phone`, data, {
                headers: authHeader(),
            });
            setSuccessful(false);
            setIsSubmitting(false);
            //console.log('Registration successful:', response);
            // Close the modal here or perform any other actions
        } catch (error) {
            alert('error');
            setSuccessful(false);
            setIsSubmitting(false);
        }
    };

    return (
        <div className={classNames(styles.root, className)}>
            <div className={styles['settings-container']}>
                <div className={styles['settings-group']}>
                    <h4 className={styles['h4-settings']}>{tl('Organisation')}</h4>
                    <div className={styles.divider} />

                    <>
                        <div className={`${styles['settings-group']}`}>
                            <div className={styles['settings-input']}>
                                <p className={styles.label}>{tl('Name')}:</p>
                                <p className={styles.small}>{organizationName}</p>
                            </div>
                            <div className={styles.divider} />

                            <div className={styles['settings-input']}>
                                <p className={styles.label}>{tl('Primairy phone number')}:</p>
                            </div>
                            <div className={styles['settings-input']}>
                                <input
                                    className={styles['form-input']}
                                    type="text"
                                    value={phone}
                                    onChange={handlePhoneChange}
                                />
                            </div>
                            <div className={styles.divider} />
                            <div className={styles['settings-input']}>
                                <p className={styles.label}>{tl('Secundairy phone number')}:</p>
                            </div>
                            <div className={styles['settings-input']}>
                                <input
                                    className={styles['form-input']}
                                    type="text"
                                    value={phone2}
                                    onChange={handlePhone2Change}
                                />
                            </div>
                            <div className={styles.divider} />
                            <div className={styles['settings-input']}>
                                <button
                                    type="submit"
                                    className={styles['button']}
                                    disabled={isSubmitting}
                                    onClick={() => submitForm()}
                                >
                                    <span>{isSubmitting ? tl('Send') + '...' : tl('Save')}</span>
                                </button>
                            </div>
                        </div>
                    </>
                </div>
                <div className={styles['settings-group']}>
                    <h4 className={styles['h4-settings']}>{tl('Account details')}:</h4>
                    <div className={styles.divider} />
                    <div className={styles['settings-input']}>
                        <p className={styles.label}>{tl('Username')}:</p>
                        <p className={styles.small}>{tokenData.username}</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['settings-input']}>
                        <p className={styles.label}>{tl('Full name')}:</p>
                        <p className={styles.small}>
                            {tokenData.firstname} {tokenData.lastname}
                        </p>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles['settings-input']}>
                        <p className={styles.label}>{tl('Email')}:</p>
                        <p className={styles.small}>{tokenData.email}</p>
                    </div>
                    <div className={styles.divider} />
                    <div className={`${styles['settings-input']}`}>
                        <p className={styles.label}>{tl('Language')}:</p>
                        <div className={styles['settings-input-lang']}>
                            <div className={styles['settings-lang']} onClick={(e) => lang('nl')}>
                                <ReactSVG src={iconFlagNL} />
                            </div>
                            <div className={styles['settings-lang']} onClick={(e) => lang('en')}>
                                <ReactSVG src={iconFlagGB} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
