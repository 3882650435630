import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ModalAddTaskTemplate } from '../../components/modals/add-task-template';
import { ModalButton } from '../../components/modals/modal';
import { tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from './test.module.scss';

export const Test = ({}) => {
    const [showModalAddTaskTemplate, setShowModalAddTaskTemplate] = useState(false);
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);

    const fetchLocationsData = async () => {};

    useEffect(() => {});

    const callbackAddTaskTemplate = async () => {
        setShowModalAddTaskTemplate(false);
    };

    return (
        <>
            <HeaderTitle title={tl('HAACP project')} description={tl('Dev only')} />

            <>
                <section className={styles['cell-list-container']}>
                    <div className="grid-container">
                        <ModalButton
                            callBack={() => setShowModalAddTaskTemplate(true)}
                            style=""
                            icon="plus"
                            text={tl('Add task')}
                        />
                    </div>
                </section>
                {showModalAddTaskTemplate && (
                    <ModalAddTaskTemplate callBack={() => callbackAddTaskTemplate()} />
                )}
            </>
        </>
    );
};
