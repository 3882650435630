import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import { CardCell } from '../../components/cards/card-cell';
import { HeaderTitle } from '../../components/header-title/header-title';
import { ModalEditCell } from '../../components/modals/edit-cell';
import GeneratePdf from '../../components/modals/generate-pdf';
import { ModalButton } from '../../components/modals/modal';
import modalStyle from '../../components/modals/modal.module.scss';
import { PopupRemoveCell } from '../../components/popup/remove-cell';
import BASE_URL from '../../services/apiConfig';
import { authHeader, tokenDecoded, tokenGet, tokenValid } from '../../services/auth';
import { tl } from '../../services/language/language';
import styles from './cells.module.scss';

export interface LocationProps {}

export const Cells = ({}: LocationProps) => {
    const [showPdfModal, setShowPdfModal] = useState(false);
    const { id } = useParams<{ id: string }>();
    const [cellsData, setCellsData] = useState<any>([]);
    const [cellDataLoaded, setCellDataLoaded] = useState(false);
    const [cellsDataBase, setCellsDataBase] = useState<any>([]);
    const [cellsDescription, setCellsDescription] = useState('');
    const [currentCell, setCurrentCell] = useState<any>(null);
    const [location, setLocation] = useState<Number>(0);
    const [locations, setLocations] = useState<Object[]>([]);
    const [showModalEditCell, setShowModalEditCell] = useState(false);
    const [showPopupRemoveCell, setShowPopupRemoveCell] = useState(false);
    const token = tokenGet();
    const validToken = tokenValid(token);
    const tokenData = tokenDecoded(token);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [endDate, setEndDate] = useState<any>(new Date());
    const [showDatePickers, setShowDatePickers] = useState(false);
    const [showCellList, setShowCellList] = useState(true);
    //console.log(tokenData);

    const organizationId = tokenData.organisation_id;

    const fetchLocationsData = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/organisation/`, {
                headers: authHeader(),
            });
            //console.log(response);
            var locations = response.data.results.locations;
            //console.log(locations);
            setLocations(locations);
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCellsData = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/organisation/` + tokenData.organisation_id + `/cells`,
                {
                    headers: authHeader(),
                },
            );
            console.log(response.data.results);
            setCellsDataBase(response.data.results);
            setCellsData(response.data.results);
            setCellDataLoaded(true);
            var cellLength = response.data.results.length;
            if (cellLength > 0) {
                setCellsDescription(tl('Number of fridges') + `: ${cellLength}`);
            } else {
                setCellsDescription(tl('No fridges yet'));
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchLocationsData();
        fetchCellsData();
    }, [id]);

    useEffect(() => {
        var cellsData2 = cellsDataBase;
        if (location) {
            var filteredData = cellsData2.filter((cell: any) => cell.location_id === location);
        } else {
            var filteredData = cellsData2;
        }

        setCellsData(filteredData);
    }, [location]);
    const openEditCellModal = (cell: any) => {
        setCurrentCell(cell);
        setShowModalEditCell(true);
    };

    const openPdf = () => {
        setShowPdfModal(true);
    };

    const callbackEditCell = async () => {
        fetchCellsData();
        setShowModalEditCell(false);
    };
    const callbackEditCell2 = async () => {
        setShowModalEditCell(false);
        setShowPopupRemoveCell(true);
    };
    const callbackRemoveCell = async () => {
        setShowPopupRemoveCell(false);
        fetchCellsData();
    };

    return (
        <>
            <HeaderTitle title={tl('Fridges')} description={cellsDescription} />

            <>
                <section className={styles['cell-list-container']}>
                    <div className="grid-container">
                        {locations.length != 0 && (
                            <div className={styles['location-tabs']}>
                                <ul className="">
                                    <li
                                        className={location === 0 ? styles['active'] : ''}
                                        onClick={(e) => setLocation(0)}
                                    >
                                        {tl('All locations')}
                                    </li>
                                    {locations.map((location2: any) => (
                                        <li
                                            className={
                                                location === location2.id ? styles['active'] : ''
                                            }
                                            onClick={(e) => setLocation(location2.id)}
                                        >
                                            {location2.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <ul className={styles['cell-list']} id="cell-list">
                            {cellsData.map((cell: any) => (
                                <CardCell cell={cell} callBack={() => openEditCellModal(cell)} />
                            ))}
                        </ul>

                        <div
                            className={`${modalStyle['modal-buttons']}`}
                            style={{ display: 'none' }}
                        >
                            <ModalButton
                                callBack={() => setShowPdfModal(true)}
                                style="light"
                                icon="export"
                                text="Overzicht exporteren"
                            />
                            {showPdfModal && (
                                <GeneratePdf callBack={() => setShowPdfModal(false)} />
                            )}
                        </div>
                    </div>
                </section>
                {showModalEditCell && (
                    <ModalEditCell
                        cell={currentCell}
                        callBack={() => callbackEditCell()}
                        callBack2={() => callbackEditCell2()}
                    />
                )}
                {showPopupRemoveCell && (
                    <PopupRemoveCell cell={currentCell} callBack={() => callbackRemoveCell()} />
                )}
            </>
        </>
    );
};
