import { DateTime } from 'luxon';
import { tl } from '../../services/language/language';

export const TimeAgoFormatted = (timestamp2: any) => {
    var timestamp = parseInt(timestamp2);

    if (isNaN(timestamp)) {
        return '-';
    }
    if (timestamp < 1) {
        return '-';
    }
    var timestampNow = new Date().getTime() / 1000;
    var formattedDate = '';
    if (timestampNow - 3600 * 24 < timestamp) {
        formattedDate = tl('Today');
    } else if (timestampNow - 3600 * 24 * 2 < timestamp) {
        formattedDate = tl('Yesterday');
    } else if (timestampNow - 3600 * 24 * 2 < timestamp) {
        formattedDate = tl('Yesterday');
    } else {
        const today = new Date();
        const day = DateTime.fromSeconds(timestamp).toFormat('d', { locale: 'nl' });
        const month = DateTime.fromSeconds(timestamp).toFormat('MMMM', { locale: 'nl' });
        formattedDate = day + ' ' + month;
    }

    const hours = DateTime.fromSeconds(timestamp).toFormat('H', { locale: 'nl' });
    const minutes = DateTime.fromSeconds(timestamp).toFormat('mm', { locale: 'nl' });
    var formattedTime = hours + ':' + minutes;
    return formattedDate + ' ' + formattedTime;
};
