import classNames from 'classnames';
import { logout } from '../../services/auth-service';
import { tl } from '../../services/language/language';
import { PersonalSettings } from '../personal-settings/personal-settings';
import styles from './account-page.module.scss';

export interface AccountPageProps {
    className?: string;
    organizationName?: string;
}

export const AccountPage = ({ className, organizationName }: AccountPageProps) => {
    const openWhatsApp = () => {
        //window.open('https://web.whatsapp.com/send?phone=31626987065', '_blank');
    };

    const handleLogout = () => {
        logout();
        window.location.reload(); // Laad app opnieuw ivm uitloggen
    };

    return (
        <div className={classNames(styles.root, className)}>
            <PersonalSettings organizationName={organizationName} />
            <div className={styles.action}>
                {/*
                <button className={styles.support} onClick={openWhatsApp}>
                    // <p className={styles.help}>support</p>
                    </button>
                */}

                <div className={styles['action-extra']}>
                    <button className={styles.support} onClick={handleLogout}>
                        <p className={styles.help}>{tl('Logout')}</p>
                    </button>
                </div>
            </div>
        </div>
    );
};
