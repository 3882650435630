import { useState } from 'react';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import iconEnvelope from '../../assets/icons/envelope.svg';
import logo from '../../assets/logo.svg';
import { tl } from '../../services/language/language';
import styles from './forms.module.scss';

import { passwordforget } from '../../services/auth-service';
import { Button } from '../button/button';
import { LanguageSwitch } from '../language-switch/language-switch';

export interface LoginProps {
    className?: string;
}

export const Passwordforget = ({ className }: LoginProps) => {
    let navigate: NavigateFunction = useNavigate();
    var defaultFormValues = { email: '' };
    const [loading, setLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [formValues, setFormValues] = useState<any>(defaultFormValues);

    const updateFormValues = (name: any, value: any) => {
        var formValues2 = formValues;
        formValues2[name] = value;
        setFormValues(formValues2);
    };

    const submitForm = async () => {
        var email2 = formValues['email'];
        await passwordforget(email2).then(
            () => {
                //navigate('/');
                // window.location.reload();
                setErrorMessage('gooed');
            },
            (error) => {
                setErrorMessage('error');
            },
        );
    };

    return (
        <>
            <LanguageSwitch />
            <section className={styles['section-forms']}>
                <div className={styles['logo']}>
                    <img src={logo} alt="Albird" />
                </div>

                <div className={styles['box']}>
                    <div className={styles['inner']}>
                        <h4>{tl('Password forget')}</h4>
                        {errorMessage ? (
                            <>
                                <div className={styles['message']}>{tl('Password link send!')}</div>
                            </>
                        ) : (
                            <>
                                <div className={styles['form']}>
                                    <div className={styles['form-row']}>
                                        <div className={styles['icon']}>
                                            <ReactSVG src={iconEnvelope} />
                                        </div>

                                        <input
                                            type="email"
                                            name="email"
                                            placeholder={tl('Email')}
                                            onChange={(e) =>
                                                updateFormValues('email', e.target.value)
                                            }
                                        />
                                    </div>

                                    <Button
                                        callBack={submitForm}
                                        style=""
                                        icon=""
                                        text={tl('Reset')}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles['sub-links']}>
                        <Link to="/">{tl('Back to login')}</Link>
                    </div>
                </div>
            </section>
        </>
    );
};
