import axios from 'axios';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/CustomEase';
import { useEffect, useState } from 'react';
import BASE_URL from '../../services/apiConfig';
import { authHeader, decodeToken } from '../../services/auth';
import { tl } from '../../services/language/language';
import { closeModal, ModalButton, openModal } from '../modals/modal';
import modalStyle from './modal.module.scss';

import { useNavigate } from 'react-router-dom';
gsap.registerPlugin(CustomEase);

export interface ModalEditCellProps {
    callBack: () => void;
}
export interface Location {
    id: number;
    name: string;
}
export const ModalEditPassword = ({ callBack }: ModalEditCellProps) => {
    const token = localStorage.getItem('user')!;
    const tokenData = decodeToken();
    const navigate = useNavigate();

    const [locations, setLocations] = useState<Location[]>([]);
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword(value);
    };
    const handlePassword2Change = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setPassword2(value);
    };

    useEffect(() => {}, []);

    const buttonActionSubmit = async () => {
        if (password !== password2) {
            alert(tl('Passwords do not match'));
            return false;
        }
        var hash = btoa(btoa(tokenData.email + '-' + tokenData.id));
        //console.log(tokenData);
        //console.log(hash);
        try {
            const updatedData = {
                hash,
                password,
            };

            const response = await axios.post(`${BASE_URL}/user/passwordreset`, updatedData, {
                headers: authHeader(),
            });
            //console.log(response);

            closeModal(callBack);
        } catch (error) {
            console.error('Error updating cell data:', error);
        }
    };

    useEffect(() => {
        openModal();
    });

    return (
        <>
            <div className={modalStyle['modal-overlay']}>
                <div
                    className={modalStyle['modal-background']}
                    id="modal-background"
                    onClick={() => closeModal(callBack)}
                ></div>

                <div className={modalStyle['modal-element']} id="modal-element">
                    <div className={modalStyle['modal-content']}>
                        <div className={modalStyle['modal-title']}>
                            <h3>{tl('Change password')}</h3>
                            <h5></h5>
                        </div>
                        <div className={modalStyle['modal-form']}>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Password')}:</label>
                                <input
                                    type="password"
                                    value={password}
                                    onChange={handlePasswordChange}
                                />
                            </div>
                            <div className={modalStyle['form-group']}>
                                <label htmlFor="name">{tl('Repeat password')}:</label>
                                <input
                                    type="password"
                                    value={password2}
                                    onChange={handlePassword2Change}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={modalStyle['modal-buttons']}>
                        <ModalButton
                            callBack={buttonActionSubmit}
                            style=""
                            icon="check"
                            text={tl('Save')}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
