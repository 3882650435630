var development = true;
var hostname = window.location.hostname;
var BASE_URL: string = 'https://lucsteve.nl';
if (hostname == 'localhost') {
    development = true;
    // BASE_URL = 'https://dev01api.albird.nl';
} else if (hostname == 'dev01.albird.nl') {
    development = true;
    // BASE_URL = 'https://dev01api.albird.nl';
}

//const BASE_URL: string = 'http://localhost:3006';

export default BASE_URL;
